import { store } from "../../redux/store";
import { useState, useEffect } from "react";
import IwdDataGrid from "../../pages/IwdDataGrid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { Form } from "@rjsf/mui";
import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { setErrors } from "../../redux/slices/errorSlice";
import validator from "@rjsf/validator-ajv6";
import { prepareParams } from "../../utils";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import ModalDialog from "../meters/ModalDialog";
import {
  Grid,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import theme from "../../theme";
import { oneOf } from "@jsonforms/examples";

const objectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={3}>
      {props.properties?.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const Jobs = () => {
  const [getJobs] = store.useLazyGetJobsQuery();
  const dispatch = useDispatch();
  const [stopJob, { isSuccess }] = store.useStopJobMutation();
  const [modal, setModal] = useState(false);
  const [jobId, setJobId] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const jobs = useSelector((state) => state?.jobsSlice?.jobs);
  const count = useSelector((state) => state?.jobsSlice?.count);
  const [refreshParams, setRefreshParams] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const stateEnum = [
    { const: "", title: t("all") },
    { const: "scheduled", title: t("scheduled") },
    { const: "available", title: t("available") },
    { const: "executing", title: t("executing") },
    { const: "retryable", title: t("retryable") },
    { const: "completed", title: t("completed") },
    { const: "discarded", title: t("discarded") },
    { const: "cancelled", title: t("cancelled") },
  ];

  const queueEnum = [
    { const: "", title: t("all") },
    { const: "default", title: t("default") },
    { const: "system_management", title: t("system_management") },
  ];
  useEffect(() => {
    dispatch(setBreadCrumbs("jobs"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);

  const bearer = localStorage.getItem("user_token");
  let fileName = "";
  const onDownloadClick = (id) => {
    /*     dispatch(loadingOn()); */

    fetch(`${window.BASE_URL}/api/v1/job/${id}/download`, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.api+json",
        Authorization: `Bearer ${bearer}`,
      },
      /*  body: JSON.stringify(prepareBodyWithoutId(params, "plugin")), */
    })
      .then((response) => {
        const contentDisposition = response.headers.get("content-disposition");
        fileName = contentDisposition
          .split(";")
          .find((part) => part.trim().startsWith("filename="))
          .split("=")[1]
          .trim();
        if (!response.ok) {
          throw new Error(
            `Network response was not ok, status: ${response.status}`
          );
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
      })
      .then(() => {
        /*   setExportModal(false); */
        /*     dispatch(loadingOff()); */
      })
      .catch((error) => {
        console.error("Error during fetch:", error);

        dispatch(setErrors({ error: "Esportazione non è andata a buon fine" }));
      });
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,

      flex: 1,
      hide: false,
    },
    {
      field: "state",
      headerName: t("state"),
      type: "string",
      editable: false,
      flex: 4,
      editable: false,
    },
    /* {
      field: "priority",
      headerName: t("priority"),
      type: "string",
      editable: false,
      flex: 4,
      editable: false,
    }, */
    {
      field: "queue",
      headerName: t("queue"),
      type: "string",
      editable: false,
      flex: 4,
      editable: false,
    },
    {
      field: "errors",
      headerName: t("errors"),
      type: "string",
      editable: false,
      flex: 4,
      editable: false,
    },
    {
      field: "inserted_at",
      headerName: t("inserted_at"),
      type: "string",
      editable: false,
      flex: 4,
      editable: false,
    },
    {
      field: "filename",
      headerName: t("filename"),
      type: "string",
      editable: false,
      flex: 4,
      editable: false,
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "string",
      sortable: false,
      editable: false,
      nosort: true,
      flex: 3,
      headerAlign: "right",
      renderCell: (params) => {
        return (
          <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
            <Tooltip title={t("download_file")}>
              {params?.value?.queue == "export_invites" &&
                params?.value?.stato == "completed" && (
                  <IconButton
                    onClick={() => onDownloadClick(params?.value?.id)}
                  >
                    <DownloadIcon
                      sx={{ fill: theme?.palette?.success?.main }}
                    />
                  </IconButton>
                )}
            </Tooltip>

            <IconButton
              disabled={!params?.value?.state}
              onClick={() => onStopClick(params?.value?.id)}
            >
              <DoNotDisturbOnIcon
                sx={{
                  color: params?.value?.state
                    ? theme.palette.error.main
                    : "gray",
                }}
              />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const rows = jobs?.map((item) => ({
    id: item?.id,
    state: t(item?.attributes?.state),
    priority: item?.attributes?.priority,
    queue: t(item?.attributes?.queue),
    errors: item?.attributes?.errors?.length,
    filename: item?.attributes?.args?.name ?? "--",
    inserted_at: t("dt_n", {
      val: new Date(item?.attributes?.inserted_at),
    }),
    actions: {
      id: item?.id,
      stato: item?.attributes?.state,
      queue: item?.attributes?.queue,
      state:
        item?.attributes?.state == "executing" ||
        item?.attributes?.state == "retryable" ||
        item?.attributes?.state == "scheduled",
    },
  }));
  const paramsList = {
    page: page,
    per_page: perPage,
    state: searchParams.get("state"),
    queue: searchParams.get("queue"),
  };
  useEffect(() => {
    if (refreshParams) {
      getJobs({ ...paramsList });
      setRefreshParams(false);
    }
  }, [refreshParams]);

  useEffect(() => {
    getJobs({ ...paramsList });
  }, [page]);
  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessage(t("job_stopped")));
      getJobs({ ...paramsList });
      setJobId(null);
      closeModal();
    }
  }, [isSuccess]);
  const schema = {
    type: "object",
    properties: {
      state: {
        id: 1,
        title: t("state"),
        type: "string",
        oneOf: [...stateEnum],
        default: paramsList?.state,
      },
      queue: {
        id: 1,
        title: t("queue"),
        type: "string",
        oneOf: [...queueEnum],
        default: paramsList?.queue,
      },
    },
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": objectFieldTemplate,

    "ui:submitButtonOptions": {
      submitText: t("search"),

      props: {
        className: "bntListSearch",
        variant: window.BUTTON_TYPE,
      },
    },
  };
  const onSubmitClick = (obj) => {
    obj["page"] = 1;
    obj["per_page"] = perPage;

    const params = prepareParams(obj);
    navigate({
      pathname: "/jobs",
      search: params.toString(),
    });

    setPage(1);
    setRefreshParams(true);
  };
  const onStopClick = (id) => {
    setModal(true);
    setJobId(id);
  };
  const closeModal = () => {
    setModal(false);
  };
  const onConfirmClick = () => {
    stopJob(jobId);
  };
  return (
    <>
      <ModalDialog open={modal} close={closeModal} title={t("stop_job")}>
        <Box>
          <Typography variant="h5">{t("stop_job_text")}</Typography>
          <Box justifyContent={"space-between"} display={"flex"} sx={{ mt: 4 }}>
            <Button
              sx={{
                background: theme.palette.error.main,
                "&:hover": {
                  background: theme.palette.error.main,
                },
              }}
              onClick={onConfirmClick}
            >
              {t("yes")}
            </Button>
            <Button onClick={closeModal}>{t("no")}</Button>
          </Box>
        </Box>
      </ModalDialog>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {t("search_form")}
        </AccordionSummary>
        <AccordionDetails>
          <Form
            schema={schema}
            formData={formData}
            showErrorList={false}
            onChange={(changeEvent) => setFormData(changeEvent.formData)}
            onSubmit={(values) => {
              onSubmitClick(values.formData);
            }}
            validator={validator}
            uiSchema={uiSchema}
          />
        </AccordionDetails>
      </Accordion>
      <Box sx={{ mt: 2 }}>
        <IwdDataGrid
          sort={false}
          columns={columns}
          rows={rows ?? []}
          count={count}
          height={600}
          paginationMode={"server"}
          perPage={perPage}
          setPage={setPage}
          page={page}
          tableName={"jobs"}
        />
      </Box>
    </>
  );
};
export default Jobs;

import { useEffect, useState } from "react";
import { store } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import IwdDataGrid from "../../pages/IwdDataGrid";
import {
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import { nameTransform } from "../../utils/utilsFunctions";
import Typography from "@mui/material/Typography";
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const Anomalies = () => {
  const dispatch = useDispatch();
  const [getAnomalies] = store.useLazyGetAomalyIndexQuery();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [value, setValue] = useState(0);
  const anomalies = useSelector((state) => state?.anomaliesSlice?.anomalies);
  const count = useSelector((state) => state?.anomaliesSlice?.count);
  const [anomalyType, setAnomalyType] = useState(window.ANOMALY_TYPES?.meters);
  const { t } = useTranslation();
  useEffect(() => {
    getAnomalies({
      status: "open",
      with_display_name: true,
      page: page,
      anomalies: anomalyType,
    });
  }, [page, anomalyType]);

  const pathSelector = (type, id) => {
    if (window.ANOMALY_TYPES?.meters?.includes(type)) {
      return `/meters/${id}`;
    }
    if (window.ANOMALY_TYPES?.block?.includes(type)) {
      return `/condominiums/${id}`;
    }
  };
  useEffect(() => {
    dispatch(setBreadCrumbs("anomalies"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
      width: 90,
      sortable: false,
      flex: 1,
    },
    {
      field: "condominium",
      headerName: t("condominium"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 3,
    },
    {
      field: "subcondominium",
      headerName: t("subcondominum"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 3,
    },
    {
      field: "apartment",
      headerName: t("apartament"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 3,
    },

    {
      field: "class",
      headerName: t("class"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },
    {
      field: "serial",
      headerName: t("serialNumber"),
      type: "number",
      sortable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "date",
      headerName: t("last_detection_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 3,
      headerAlign: "left",
    },

    {
      field: "error",
      headerName: t("anomaly"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 3,
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <Link to={pathSelector(params?.value?.type, params?.value?.id)}>
              <VisibilityIcon
                sx={{ float: "right", mt: 1, fill: theme.palette.navIcon }}
              />
            </Link>
          </Box>
        );
      },
    },
  ];
  const blockColumns = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
      width: 90,
      sortable: false,
      flex: 1,
    },

    {
      field: "condominium",
      headerName: t("condominium"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },

    {
      field: "error",
      headerName: t("anomaly"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },

    {
      field: "date",
      headerName: t("last_detection_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <Link to={pathSelector(params?.value?.type, params?.value?.id)}>
              <VisibilityIcon
                sx={{ float: "right", mt: 1, fill: theme.palette.navIcon }}
              />
            </Link>
          </Box>
        );
      },
    },
  ];
  const rows = anomalies?.map((item) => ({
    id: item?.id,
    class: item?.class ?? "--",
    serial: item?.device_serial_number,
    error: item?.display_name,
    date: t("dt", { val: new Date(item?.anomaly_update_time) }),
    condominium: item?.condominium_name,
    subcondominium: item?.sub_condominium_name,
    apartment: item?.apartment_name,
    actions: {
      id: item?.device_id,
      type: item?.anomaly_type_id,
    },
  }));
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(1);
    if (newValue == 0) {
      setAnomalyType(window.ANOMALY_TYPES?.meters);
    }
    if (newValue == 1) {
      setAnomalyType(window.ANOMALY_TYPES?.block);
    }
  };
  return (
    <>
      <Tabs value={value} onChange={handleChange}>
        <Tab label={t("meters")} />
        {window?.FORM?.blockSecktion !== "hidden" && (
          <Tab label={t("condominiums")} />
        )}
      </Tabs>
      <IwdDataGrid
        rows={rows ?? []}
        columns={value == 0 ? columns : blockColumns}
        perPage={perPage}
        setPage={setPage}
        page={page}
        count={count}
        sort={false}
        height={613}
        tableName={"anomalies"}
        paginationMode={"server"}
      />
    </>
  );
};
export default Anomalies;

import BsLogo from "../components/svgs/BsLogo";
import LogoMad from "../components/svgs/LogoMad";

export const selectLogo = () => {
  switch (window.MAINLOGO) {
    case "bs":
      return <BsLogo />;
    case "mad":
      return <LogoMad />;
  }
};

const BsLogo = () => {
  return (
    <div>
      <svg
        version="1.2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 375 144"
        width="310"
        height="119"
      >
        <defs>
          <clipPath clipPathUnits="userSpaceOnUse" id="cp1">
            <path d="m26.73 29.46h106.5v94.5h-106.5z" />
          </clipPath>
        </defs>
        <style></style>
        <g clip-path="url(#cp1)">
          <path
            fill-rule="evenodd"
            d="m77.7 30l22.6 8.3v35l-10.5 6.8v31.7q1.6 0.1 3.3 0.3v-29.3l14.9-9.7 12.8 8.3v36.5c4.5 1.6 8.6 3.4 12.4 5.4h-106.4c3.7-2 7.9-3.8 12.4-5.3v-41.3l14.9-9.7 8.6 5.6v-32.9zm4.2 53.8v5.6l4.8 1.8v-5.7zm24-6.5l-10.6 6.8v28.3q5.4 0.6 10.6 1.7zm-24-4.5v5.7l4.8 1.7v-5.6zm-29.9-1.6l-10.6 6.8v39.3q5.1-1.6 10.6-2.8zm39.4-6v5.7l4.8 1.8v-5.7zm-9.5-3.5v5.7l4.8 1.7v-5.6zm9.5-7.6v5.7l4.8 1.7v-5.6zm-9.5-3.5v5.6l4.8 1.8v-5.7zm9.5-7.5v5.6l4.8 1.8v-5.7zm-9.5-3.5v5.7l4.8 1.8v-5.7zm-6.4-5.4l-10.5 6.8v71.4q5.2-0.6 10.5-0.7zm6.4 60.7v5.6l4.8 1.8v-5.7z"
          />
        </g>
        <path
          fill-rule="evenodd"
          d="m147.9 34.9q3.4 0 5 1.6 1.5 1.6 1.5 4.8v1q0 2.2-0.7 3.6-0.6 1.4-2.1 2.1 1.8 0.7 2.5 2.2 0.8 1.6 0.8 3.8v2.3q0 3.2-1.7 4.9-1.7 1.7-5 1.7h-6.9v-28zm-2.3 15.4v8.6h2.5q1.2 0 1.7-0.6 0.6-0.6 0.6-2.2v-2.4q0-1.9-0.6-2.7-0.7-0.7-2.2-0.7zm0-11.4v7.4h1.7q1.2 0 1.9-0.6 0.7-0.7 0.7-2.4v-1.5q0-1.5-0.5-2.2-0.5-0.7-1.7-0.7z"
        />
        <path d="m173.5 34.9v21.6q0 1.5 0.7 2.1 0.6 0.6 1.6 0.6 1.1 0 1.7-0.6 0.6-0.6 0.6-2.1v-21.6h4.2v21.3q0 3.4-1.7 5.3-1.7 1.8-4.9 1.8-3.2 0-4.9-1.8-1.7-1.9-1.7-5.3v-21.3z" />
        <path d="m197.1 34.9h4.4v28h-4.4z" />
        <path d="m216.5 34.9h4.4v24h7.3v4h-11.7z" />
        <path
          fill-rule="evenodd"
          d="m241.9 34.9h7q3.3 0 4.9 1.8 1.7 1.7 1.7 5.1v14.2q0 3.4-1.7 5.2-1.6 1.7-4.9 1.7h-7zm4.4 4v20h2.5q1.1 0 1.6-0.6 0.7-0.6 0.7-2.1v-14.6q0-1.5-0.7-2.1-0.5-0.6-1.6-0.6z"
        />
        <path d="m270.1 34.9h4.4v28h-4.4z" />
        <path d="m293.5 42.6v20.3h-4v-28h5.5l4.5 16.8v-16.8h4v28h-4.6z" />
        <path d="m325.1 47.3h6.1v8.9q0 3.4-1.6 5.2-1.7 1.8-4.9 1.8-3.3 0-5-1.8-1.6-1.8-1.6-5.2v-14.6q0-3.4 1.6-5.2 1.7-1.8 5-1.8 3.2 0 4.9 1.8 1.6 1.8 1.6 5.2v2.7h-4.1v-3q0-1.5-0.7-2.1-0.6-0.6-1.6-0.6-1.1 0-1.7 0.6-0.6 0.6-0.6 2.1v15.2q0 1.5 0.6 2.1 0.6 0.6 1.7 0.6 1 0 1.6-0.6 0.7-0.6 0.7-2.1v-5.2h-2z" />
        <path d="m140.7 86.6q0-3.4 1.6-5.2 1.6-1.8 4.9-1.8 3.2 0 4.8 1.8 1.7 1.8 1.7 5.2v0.9h-4.2v-1.2q0-1.5-0.6-2.1-0.6-0.6-1.6-0.6-1 0-1.6 0.6-0.6 0.6-0.6 2.1 0 1.5 0.6 2.6 0.7 1.1 1.6 2.1 1 1 2.1 1.9 1.1 1 2.1 2.2 0.9 1.1 1.6 2.6 0.6 1.5 0.6 3.5 0 3.4-1.7 5.2-1.6 1.8-4.9 1.8-3.2 0-4.9-1.8-1.6-1.8-1.6-5.2v-1.7h4.1v2q0 1.5 0.7 2.1 0.6 0.6 1.6 0.6 1.1 0 1.7-0.6 0.6-0.6 0.6-2.1 0-1.5-0.6-2.6-0.7-1.1-1.6-2.1-1-0.9-2.1-1.9-1.1-1-2.1-2.1-0.9-1.2-1.6-2.7-0.6-1.5-0.6-3.5z" />
        <path d="m171.7 91.7h6.1v4h-6.1v8.2h7.6v4h-12v-28h12v4h-7.6z" />
        <path
          fill-rule="evenodd"
          d="m202.4 107.9q-0.1-0.3-0.2-0.5-0.1-0.3-0.1-0.6-0.1-0.4-0.1-0.9 0-0.6 0-1.4v-4.4q0-2-0.7-2.8-0.7-0.8-2.2-0.8h-1.5v11.4h-4.4v-28h6.6q3.5 0 5 1.6 1.5 1.6 1.5 4.8v2.2q0 4.4-2.8 5.7 1.6 0.7 2.3 2.3 0.6 1.5 0.6 3.7v4.3q0 1.1 0.1 1.8 0 0.8 0.4 1.6zm-4.8-24v8.6h1.7q1.2 0 1.9-0.6 0.7-0.7 0.7-2.4v-2.7q0-1.5-0.5-2.2-0.6-0.7-1.7-0.7z"
        />
        <path d="m227 102.8l3.4-22.9h4l-4.3 28h-6.6l-4.3-28h4.5z" />
        <path d="m247.6 79.9h4.4v28h-4.4z" />
        <path d="m279.1 97.5v3.7q0 3.4-1.7 5.2-1.7 1.8-4.9 1.8-3.2 0-4.9-1.8-1.7-1.8-1.7-5.2v-14.6q0-3.4 1.7-5.2 1.7-1.8 4.9-1.8 3.2 0 4.9 1.8 1.7 1.8 1.7 5.2v2.7h-4.2v-3q0-1.5-0.6-2.1-0.7-0.6-1.7-0.6-1 0-1.7 0.6-0.6 0.6-0.6 2.1v15.2q0 1.5 0.6 2.1 0.7 0.6 1.7 0.6 1 0 1.7-0.6 0.6-0.6 0.6-2.1v-4z" />
        <path d="m297.3 91.7h6v4h-6v8.2h7.5v4h-12v-28h12v4h-7.5z" />
        <path d="m318.1 86.6q0-3.4 1.7-5.2 1.6-1.8 4.8-1.8 3.3 0 4.9 1.8 1.6 1.8 1.6 5.2v0.9h-4.2v-1.2q0-1.5-0.5-2.1-0.6-0.6-1.7-0.6-1 0-1.6 0.6-0.6 0.6-0.6 2.1 0 1.5 0.7 2.6 0.6 1.1 1.6 2.1 0.9 1 2 1.9 1.1 1 2.1 2.2 1 1.1 1.6 2.6 0.6 1.5 0.6 3.5 0 3.4-1.6 5.2-1.7 1.8-4.9 1.8-3.3 0-4.9-1.8-1.7-1.8-1.7-5.2v-1.7h4.2v2q0 1.5 0.6 2.1 0.6 0.6 1.7 0.6 1 0 1.6-0.6 0.6-0.6 0.6-2.1 0-1.5-0.6-2.6-0.6-1.1-1.6-2.1-1-0.9-2.1-1.9-1.1-1-2-2.1-1-1.2-1.6-2.7-0.7-1.5-0.7-3.5z" />
      </svg>
    </div>
  );
};
export default BsLogo;

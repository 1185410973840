import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Alert,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LogoMad from "./svgs/LogoMad";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectLogo } from "../utils/logoSelector";
import { setBreadCrumbs } from "../redux/slices/breadCrumbMeterSlice";
import { store } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

const ConfirmResetPasswordPage = () => {
  const validation = useSelector((state) => state?.authSlice?.validation);
  const [getValidation] = store.useLazyGetValidationQuery();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const initialCode = searchParams.get("code") ?? "";
  const [confirmRegistration, { isSuccess }] =
    store.useConfirmRegistrationMutation();
  const [code, setCode] = useState(initialCode);
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState({
    code: "",
    password: "",
    passwordConfirmation: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [submitError, setSubmitError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    getValidation();
  }, []);

  const [passwordRules, setPasswordRules] = useState({
    minLength: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumbers: false,
    hasSpecialChar: false,
  });

  const regex = {
    minLength: {
      regex: new RegExp(validation?.minLength?.regex),
      value: validation?.minLength?.value,
    },
    uppercaseLength: {
      regex: new RegExp(validation?.uppercaseLength?.regex),
      value: validation?.uppercaseLength?.value,
    },
    lowercaseLength: {
      regex: new RegExp(validation?.lowercaseLength?.regex),
      value: validation?.lowercaseLength?.value,
    },
    numberLength: {
      regex: new RegExp(validation?.numberLength?.regex),
      value: validation?.numberLength?.value,
    },
    symbolLength: {
      regex: new RegExp(validation?.symbolLength?.regex),
      value: validation?.symbolLength?.value,
    },
  };

  const validatePassword = (password) => {
    setPasswordRules({
      minLength: regex.minLength.regex.test(password),
      hasUpperCase: regex.uppercaseLength.regex.test(password),
      hasLowerCase: regex.lowercaseLength.regex.test(password),
      hasNumbers: regex.numberLength.regex.test(password),
      hasSpecialChar: regex.symbolLength.regex.test(password),
    });
  };

  useEffect(() => {
    console.log("Password rules updated:", passwordRules);
  }, [passwordRules]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "code") setCode(value);
    if (name === "password") {
      setPassword(value);
      validatePassword(value);
    }
    if (name === "passwordConfirmation") setPasswordConfirmation(value);
  };

  useEffect(() => {
    dispatch(setBreadCrumbs("Login"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowPasswordConfirmation = () =>
    setShowPasswordConfirmation(!showPasswordConfirmation);

  const validate = () => {
    let tempErrors = { code: "", password: "", passwordConfirmation: "" };
    let isValid = true;

    if (!code) {
      tempErrors.code = "Code is required";
      isValid = false;
    }

    if (!password) {
      tempErrors.password = "Password is required";
      isValid = false;
    } else if (!passwordRules.minLength) {
      tempErrors.password = `Password must be at least ${regex.minLength.value} characters`;
      isValid = false;
    } else if (!passwordRules.hasUpperCase) {
      tempErrors.password = `Password must have at least ${regex.uppercaseLength.value} uppercase letters`;
      isValid = false;
    } else if (!passwordRules.hasLowerCase) {
      tempErrors.password = `Password must have at least ${regex.lowercaseLength.value} lowercase letters`;
      isValid = false;
    } else if (!passwordRules.hasNumbers) {
      tempErrors.password = `Password must have at least ${regex.numberLength.value} numbers`;
      isValid = false;
    } else if (!passwordRules.hasSpecialChar) {
      tempErrors.password = `Password must have at least ${regex.symbolLength.value} special characters`;
      isValid = false;
    }

    if (!passwordConfirmation) {
      tempErrors.passwordConfirmation = "Password confirmation is required";
      isValid = false;
    } else if (password !== passwordConfirmation) {
      tempErrors.passwordConfirmation = "Passwords do not match";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitError("");
    setSuccessMessage("");

    if (validate()) {
      confirmRegistration({
        code: code,
        password: password,
        password_confirmation: passwordConfirmation,
      }).catch((error) => {
        console.error("Error occurred:", error);
        setSubmitError("An error occurred. Please try again later.");
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setSuccessMessage("Your password has been reset successfully.");
      navigate("/login");
    }
  }, [isSuccess, navigate]);

  const isPasswordValid = () => {
    return (
      passwordRules.minLength &&
      passwordRules.hasUpperCase &&
      passwordRules.hasLowerCase &&
      passwordRules.hasNumbers &&
      passwordRules.hasSpecialChar &&
      passwordConfirmation === password
    );
  };

  return (
    <>
      {validation && (
        <Container component="main" maxWidth="sm">
          <Paper
            elevation={6}
            sx={{
              padding: 4,
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: 3,
              backgroundColor: "#f5f5f5",
            }}
          >
            <Grid container justifyContent="center" sx={{ mb: 4 }}>
              <Box component="div">{selectLogo()}</Box>
            </Grid>
            <Typography
              component="h1"
              variant="h5"
              sx={{ mb: 2, fontWeight: "bold" }}
            >
              {t("confirm_register")}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              sx={{ mb: 3 }}
            >
              {t("confirm_register_text")}
            </Typography>

            <Box
              component="form"
              noValidate
              sx={{ mt: 1 }}
              onSubmit={handleSubmit}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="code"
                label={t("code")}
                name="code"
                autoFocus
                value={code}
                onChange={handleChange}
                error={Boolean(errors.code)}
                helperText={errors.code}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={t("password")}
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="new-password"
                value={password}
                onChange={handleChange}
                error={Boolean(errors.password)}
                helperText={errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="passwordConfirmation"
                label={t("confirm_password")}
                type={showPasswordConfirmation ? "text" : "password"}
                id="passwordConfirmation"
                autoComplete="new-password"
                value={passwordConfirmation}
                onChange={handleChange}
                error={Boolean(errors.passwordConfirmation)}
                helperText={errors.passwordConfirmation}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPasswordConfirmation}
                        edge="end"
                      >
                        {showPasswordConfirmation ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <List sx={{ mb: 2 }}>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        align="center"
                        sx={{
                          color: passwordRules.minLength ? "green" : "red",
                          fontSize: "0.875rem",
                        }}
                      >
                        {regex.minLength.value > 1
                          ? t("validation.minLengthPlural", {
                              count: regex.minLength.value,
                            })
                          : t("validation.minLength", {
                              count: regex.minLength.value,
                            })}
                      </Typography>
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        align="center"
                        sx={{
                          color: passwordRules.hasUpperCase ? "green" : "red",
                          fontSize: "0.875rem",
                        }}
                      >
                        {regex.uppercaseLength.value > 1
                          ? t("validation.uppercaseLengthPlural", {
                              count: regex.uppercaseLength.value,
                            })
                          : t("validation.uppercaseLength", {
                              count: regex.uppercaseLength.value,
                            })}
                      </Typography>
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        align="center"
                        sx={{
                          color: passwordRules.hasLowerCase ? "green" : "red",
                          fontSize: "0.875rem",
                        }}
                      >
                        {regex.lowercaseLength.value > 1
                          ? t("validation.lowercaseLengthPlural", {
                              count: regex.lowercaseLength.value,
                            })
                          : t("validation.lowercaseLength", {
                              count: regex.lowercaseLength.value,
                            })}
                      </Typography>
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        align="center"
                        sx={{
                          color: passwordRules.hasNumbers ? "green" : "red",
                          fontSize: "0.875rem",
                        }}
                      >
                        {regex.numberLength.value > 1
                          ? t("validation.numberLengthPlural", {
                              count: regex.numberLength.value,
                            })
                          : t("validation.numberLength", {
                              count: regex.numberLength.value,
                            })}
                      </Typography>
                    }
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        align="center"
                        sx={{
                          color: passwordRules.hasSpecialChar ? "green" : "red",
                          fontSize: "0.875rem",
                        }}
                      >
                        {regex.symbolLength.value > 1
                          ? t("validation.symbolLengthPlural", {
                              count: regex.symbolLength.value,
                            })
                          : t("validation.symbolLength", {
                              count: regex.symbolLength.value,
                            })}
                      </Typography>
                    }
                  />
                </ListItem>
                {password?.length > 0 && passwordConfirmation?.length > 0 && (
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography
                          align="center"
                          sx={{
                            color:
                              password === passwordConfirmation
                                ? "green"
                                : "red",
                            fontSize: "0.875rem",
                          }}
                        >
                          {password === passwordConfirmation
                            ? t("validation.passwordsMatch")
                            : t("validation.passwordsDoNotMatch")}
                        </Typography>
                      }
                    />
                  </ListItem>
                )}
              </List>

              {submitError && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {submitError}
                </Alert>
              )}
              {successMessage && (
                <Alert severity="success" sx={{ mt: 2 }}>
                  {successMessage}
                </Alert>
              )}
              <Button
                type="submit"
                fullWidth
                variant={window.BUTTON_TYPE}
                disabled={!isPasswordValid()}
                sx={{ mt: 3, mb: 2 }}
              >
                {t("confirm_register")}
              </Button>
            </Box>
          </Paper>
        </Container>
      )}
    </>
  );
};

export default ConfirmResetPasswordPage;

import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PolicyIndex = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <Button onClick={() => navigate("/supply_policies/create")}>
        {t("create_new_policy")}
      </Button>
    </>
  );
};
export default PolicyIndex;

import { useParams } from "react-router-dom";
import { store } from "../../redux/store";
import { useEffect } from "react";
const MeterCommandShow = () => {
  const { id, commandId } = useParams();
  const [getCommandShow] = store.useLazyGetShowCommandQuery();
  useEffect(() => {
    if (id && commandId) {
      getCommandShow({
        meterId: id,
        commandId: commandId,
      });
    }
  }, [id, commandId]);
};
export default MeterCommandShow;

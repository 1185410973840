import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { store } from "../../redux/store";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { CircularProgress, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import debounce from "lodash.debounce";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const UserAutocomplete = ({ deviceId }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [getAssociatedUsers] = store.useLazyGetAssociatedUsersQuery();
  const [getUsers] = store.useLazyGetUsersQuery();
  const [associateAdmin, { isSuccess: assocSuccess }] =
    store.useAssociateAdminMutation();
  const associatedUsers = useSelector(
    (state) => state?.condominiumSlice?.associatedUsers
  );
  const users = useSelector((state) => state?.userSlice?.users);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const selectedUsers = value?.map((item) => item.value);

  const debValue = debounce((e) => {
    setSearchValue(e.target.value);
  }, 500);

  useEffect(() => {
    if (users) {
      setOptions(
        users?.map((item) => ({
          title: `${item?.attributes?.first_name} ${item?.attributes?.last_name}`,
          value: item?.id,
        }))
      );
    }
  }, [users]);

  useEffect(() => {
    if (users.length == 0) {
      console.log("vpiske");
      getUsers({ page: 1, per_page: 10, role: "building_administrator" });
    }
  }, [users]);

  useEffect(() => {
    if (searchValue) {
      setLoading(true);
      getUsers({ username: searchValue, role: "building_administrator" }).then(
        () => {
          setLoading(false);
        }
      );
    }
  }, [searchValue]);

  useEffect(() => {
    if (associatedUsers?.length > 0) {
      const mappedUsers = associatedUsers.map((item) => ({
        title: `${item?.attributes?.first_name} ${item?.attributes?.last_name}`,
        value: item?.id,
      }));
      setValue(mappedUsers);
    }
  }, [associatedUsers]);

  useEffect(() => {
    if (assocSuccess) {
      dispatch(setMessage(t("assoc_succ")));
      getAssociatedUsers(id);
    }
  }, [assocSuccess]);

  const onAssocClick = () => {
    const condIds = value?.map((item) => item.value);
    associateAdmin({
      id: deviceId,
      attributes: {
        relation_type: "building_administrator",
        user_ids: [...condIds],
      },
    });
  };

  return (
    <>
      <Autocomplete
        multiple
        limitTags={2}
        value={value ?? []}
        id="multiple-limit-tags"
        options={options.filter((item) => !selectedUsers?.includes(item.value))}
        getOptionLabel={(option) => option.title}
        onChange={(e, newValue) => {
          setValue(newValue);
        }}
        onInputChange={(e, newInputValue) => {
          // Обновляем searchValue напрямую при изменении текста
          setSearchValue(newInputValue);
        }}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={debValue}
            label={t("search_user")}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {loading && <CircularProgress color="inherit" size={20} />}
                </>
              ),
            }}
          />
        )}
      />
      <Button
        onClick={onAssocClick}
        variant="contained"
        sx={{ mt: 2, mb: 2, float: "right" }}
      >
        {t("assoc")}
      </Button>
    </>
  );
};

export default UserAutocomplete;

import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "Inter, sans-serif",
    color: window.THEME.typographyColor,
    h1: {
      fontSize: 50,
      fontWeight: 900,
    },
    button: {
      color: "red",
    },
  },
  palette: {
    type: "light",
    primary: {
      main: window?.THEME?.primary,
    },
    secondary: {
      main: window?.THEME?.secondary,
    },
    text: {
      primary: window.THEME.typographyPrimaryColor,
      secondary: window.THEME.typographySecondaryColor,
    },
    version: window.THEME.version,
    footer: window.THEME.footer,
    header: window.THEME.header,
    menu: window.THEME.menu,
    secondaryIcons: window.THEME.secondaryIcons,
    customPagination: window.THEME.customPaginationColor,
    headerBackground: window.THEME.headerBackground,
    navIcon: window.THEME.navIconColor,
    footerBackground: window.THEME.footerBackground,
    blockArrow: window.THEME.blockArrowIconColor,
    formField: window.THEME.muiFormFieldColor,
    chip: window.THEME.widgetChipBackgroundColor,
    chipLabel: window.THEME.widgetChipIconColor,
    error: {
      main: "#bd1e1e",
    },
    warning: {
      main: "#f6ae2d",
    },
    anomaly: {
      main: "#FFFF00",
    },
    orange: {
      main: "#FF5F1F",
    },
    blue: {
      main: "#00bfff",
    },
    messageText: {
      main: "#3c4b64",
    },
    background: {
      paper: "#fff",
    },
    device: {
      main: "#9ABAED",
      contrastText: "#fff",
    },
    group_and_district: {
      main: "#506FA1",
      contrastText: "#fff",
    },
    network: {
      main: "#3c4b64",
      contrastText: "#fff",
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: window?.THEME?.menu,
          color: "#fff",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: window.THEME.buttonText,
          backgroundColor: window?.THEME?.buttonBackGround,
          "&:hover": {
            backgroundColor: window?.THEME?.buttonBackGround,
            color: window?.THEME?.buttonHoverText,
          },
        },
      },
    },
    MuiDatePicker: {
      styleOverrides: {
        // Общие стили DatePicker
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            color: window?.THEME?.navIconColor,
          },
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            color: window?.THEME?.navIconColor,
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-footerContainer .MuiTablePagination-actions .MuiSvgIcon-root":
            {
              color: window?.THEME?.arrowPaginationColor,
            },
          "& .MuiDataGrid-footerContainer .MuiTablePagination-actions .MuiButtonBase-root:hover .MuiSvgIcon-root":
            {
              color: window?.THEME?.arrowPaginationColor,
            },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 500,
          color: window?.THEME?.muiListItemText,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: window?.THEME?.muiIcon,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: window?.THEME?.tabSelected,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: window?.THEME?.tabSelected,
            fontWeight: 600,
          },
          "&:hover": {
            backgroundColor: window?.THEME?.tabBackground,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label": {
            color: window?.THEME?.muiFormFieldLabelColor,
          },
          "& label.Mui-focused": {
            color: window?.THEME?.muiFormFieldLabelColor,
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: window?.THEME?.muiFormFieldColor,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: window?.THEME?.muiFormFieldColor,
            },
            "&:hover fieldset": {
              borderColor: window?.THEME?.muiFormFieldColor,
            },
            "&.Mui-focused fieldset": {
              borderColor: window?.THEME?.muiFormFieldColor,
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "transparent", // Цвет чекбокса (не отмечен) - прозрачный
          border: window?.THEME?.checkboxColor, // Рамка для чекбокса
          borderRadius: "2px", // Радиус углов рамки
          width: "20px", // Ширина чекбокса
          height: "20px", // Высота чекбокса
          display: "flex", // Выравнивание содержимого по центру
          alignItems: "center",
          justifyContent: "center",
          "&.Mui-checked": {
            color: "transparent", // Цвет галочки при отметке - прозрачный
            backgroundColor: "transparent", // Прозрачный фон при отметке
            border: window?.THEME?.checkboxColor, // Цвет рамки при отметке
          },
          "&:hover": {
            backgroundColor: "rgba(44, 62, 80, 0.1)",
          },
          "& .MuiSvgIcon-root": {
            fill: window?.THEME?.checkboxColor, // Цвет галочки
          },
        },
      },
    },
    /*   MuiSelect: {
      styleOverrides: {
        root: {
          color: "#000",
        },
        select: {
          "&:hover": {
            backgroundColor: "#000",
          },
          "&.Mui-focused": {
            backgroundColor: "#000",
          },
        },
        icon: {
          color: window?.THEME?.muiIcon,
        },
        outlined: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#000",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#000",
          },
        },
      },
    }, */
  },
});

export default theme;

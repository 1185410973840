import { MapContainer } from "react-leaflet";
import FormMap from "../../../form/formComponents/FormMap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { height } from "@mui/system";
import zIndex from "@mui/material/styles/zIndex";
const WidgetMapContainer = ({
  pos,
  hideAccordion,
  setPreferenceLoading,
  classValue,
  params,
}) => {
  const headerAppBarOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const center = useSelector((state) => state?.userPreferenceSlice?.mapCenter);
  const zoom = useSelector((state) => state?.userPreferenceSlice?.mapZoom);
  let style = {
    height: hideAccordion ? "60vh" : "39vh",
    width: headerAppBarOpen ? "79vw" : "91.2vw",
  };
  const mapCenter = {
    lat: 44.833839087777484,
    lng: 11.826524734497072,
  };

  return (
    <Box sx={style}>
      <MapContainer
        style={{ height: 380, zIndex: 0 }}
        id="show-device-map"
        center={center}
        zoom={zoom}
        minZoom={5}
        scrollWheelZoom={true}
      >
        <FormMap
          pos={pos}
          hideAccordion={hideAccordion}
          setPreferenceLoading={setPreferenceLoading}
          classValue={classValue}
          params={params}
        />
      </MapContainer>
    </Box>
  );
};
export default WidgetMapContainer;

import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { store } from "../../redux/store";
import { Button, Grid, Typography } from "@mui/material";
import theme from "../../theme";
import { IwdFormAuthWrapper } from "../../utils/IwdFormAuthWrapper";
import SearcheableSelect from "../form/formComponents/SearcheableSelect";
import { Box } from "@mui/system";
import { setLoraAreas } from "../../redux/slices/gatewaySlice";
import SecurityWidget from "../form/formComponents/customWidgets/SecurityWidget";
import Point from "../form/formComponents/Point";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      rowSpacing={3}
      spacing={2}
    >
      {props.properties?.map((element, i) => {
        if (element.name === "security") {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const allowedClasses = ["gateway", "Gateway", "GATEWAY"];
const GatewayCreate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [resetTwin, setResetTwin] = useState(false);
  const loraAreas = useSelector((state) => state?.gatewaySlice?.loraAreas);
  const loraMeterId = useSelector((state) => state?.gatewaySlice?.loraMeterId);
  const [mappedAreas, setMappedAreas] = useState([]);
  const security = IwdFormAuthWrapper({ section: "security" });
  const [postCommands, { isSuccess: postSuccess }] =
    store.usePostCommandsMutation();
  const digitalTwinId = useSelector(
    (state) => state?.digitalTwinSlice?.digitalTwin?.[0]?.id
  );

  const digitalTwinName = useSelector(
    (state) => state?.digitalTwinSlice?.digitalTwin?.[0]?.attributes?.name
  );
  const identifier = useSelector(
    (state) =>
      state?.digitalTwinSlice?.digitalTwin?.[0]?.attributes?.digital_twin_fields
        ?.identifier_type
  );
  const [formData, setFormData] = useState({});
  const [createdId, setCreatedId] = useState(null);
  const [test, setTest] = useState(true);
  const [createGateway, { isSuccess }] = store.useCreateGatewayMutation();
  const networkAdapters = window.GATEWAYS?.[digitalTwinId]?.map((item) => ({
    name: item,
    module: "null",
  }));
  useEffect(() => {
    if (loraAreas) {
      setMappedAreas(
        loraAreas?.map((item) => ({
          const: item?.name,
        }))
      );
    }
  }, [loraAreas]);
  const defaultrequired = ["serial_number", "name", "digital_twin"];
  const schm = !security
    ? {
        title: "New Device",
        type: "object",
        properties: {
          serial_number: {
            $id: "1",
            type: "string",
            length: "10",
            title: t("serial"),
          },

          street_address: {
            $id: "4",
            type: "string",
            title: t("street_address"),
          },
          msisdn: {
            $id: "4",
            type: "number",
            title: t("phone_number"),
          },
          name: {
            $id: "5",
            type: "string",
            title: t("name"),
          },
          time_zone: {
            $id: "6",
            type: "string",
            title: t("time_zone"),
            url: "time_zone",
            defaultTimeZone: true,
            default: "Europe/Rome",

            mapFn: (result) =>
              result?.data?.data?.map((item) => ({
                key: item?.attributes?.key,
                label: item?.attributes?.name,
                value: item?.attributes?.id,
              })),
          },
          digital_twin: {
            $id: "7",

            mapFn: (result) =>
              result?.data?.data
                ?.map((item) => ({
                  key: item.attributes?.id,
                  label: `${item.attributes?.name} (${item.attributes?.version})`,
                  value: `${item.attributes?.name} (${item.attributes?.version})`,
                  tags: item?.attributes?.digital_twin_fields?.tags,
                }))
                ?.filter(
                  (item) =>
                    item?.tags?.includes("Gateway") ||
                    item?.tags?.includes("gateway")
                ),
            /*    stateSelector: classStateSelector, */

            url: "altior/digital_twin",
            type: "string",
            title: t("digital_twin"),
            dispatched: true,
          },

          coordinates: {
            $id: "12",
            type: "object",
            properties: {
              lat: {
                $id: "28",
                type: "number",
                default: 0,
              },
              lng: {
                $id: "29",
                type: "number",
                default: 0,
              },
            },
          },
        },
        required: ["serial_number", "name", "digital_twin"],
      }
    : {
        title: "New Device",
        type: "object",
        properties: {
          serial_number: {
            $id: "1",
            type: "string",
            length: "10",
            title: t("serial"),
          },

          street_address: {
            $id: "4",
            type: "string",
            title: t("street_address"),
          },
          msisdn: {
            $id: "4",
            type: "number",
            title: t("phone_number"),
          },
          name: {
            $id: "5",
            type: "string",
            title: t("name"),
          },
          time_zone: {
            $id: "6",
            type: "string",
            title: t("time_zone"),
            url: "time_zone",
            defaultTimeZone: true,
            default: "Europe/Rome",

            mapFn: (result) =>
              result?.data?.data?.map((item) => ({
                key: item?.attributes?.key,
                label: item?.attributes?.name,
                value: item?.attributes?.id,
              })),
          },
          digital_twin: {
            $id: "7",

            mapFn: (result) =>
              result?.data?.data
                ?.map((item) => ({
                  key: item.attributes?.id,
                  label: `${item.attributes?.name} (${item.attributes?.version})`,
                  value: `${item.attributes?.name} (${item.attributes?.version})`,
                  tags: item?.attributes?.digital_twin_fields?.tags,
                }))
                ?.filter(
                  (item) =>
                    item?.tags?.includes("Gateway") ||
                    item?.tags?.includes("gateway")
                ),
            /*    stateSelector: classStateSelector, */

            url: "altior/digital_twin",
            type: "string",
            title: t("digital_twin"),
            dispatched: true,
          },

          coordinates: {
            $id: "12",
            type: "object",
            properties: {
              lat: {
                $id: "28",
                type: "number",
                default: 0,
              },
              lng: {
                $id: "29",
                type: "number",
                default: 0,
              },
            },
          },
          security: {
            $id: "2",
            type: "array",
            title: t("security"),
          },
        },
        required: ["serial_number", "name", "digital_twin"],
      };
  useEffect(() => {}, [networkAdapters]);
  useEffect(() => {
    dispatch(setBreadCrumbs("gateways/create"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  const uiSchm = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:order": [
      "digital_twin",
      "name",
      "security",
      "serial_number",

      "street_address",
      "msisdn",
      "time_zone",
      "coordinates",

      "lora",
      "mac",
      "chain",
      "areas",
    ],

    "ui:submitButtonOptions": {
      submitText: t("create"),
      props: {
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
    coordinates: {
      "ui:field": "point",
    },
    security: {
      "ui:field": "security",
    },
    time_zone: {
      "ui:field": "search",
    },
    digital_twin: {
      "ui:field": "search",
    },
    lora: {
      "ui:field": "search",
    },
  };
  const fields = {
    point: Point,
    search: SearcheableSelect,
    security: SecurityWidget,
  };
  const [schema, setSchema] = useState(schm);
  const [uiSchema, setUiSchema] = useState(uiSchm);
  useEffect(() => {}, [window.GATEWAYS]);
  const onSubmitClick = (obj) => {
    const attributes = {
      fields: {
        ...formData,
        active_network_adapters: networkAdapters?.length > 0 && [
          ...networkAdapters,
        ],
        identifier_type: identifier,
        msisdn: formData?.msisdn?.toString(),
        network_adapters: networkAdapters?.length > 0 && [...networkAdapters],
        time_zone: formData?.time_zone,
        security:
          security && formData?.security?.length > 0
            ? {
                acl: [...formData?.security],
                groups: ["/network_adapter_manager_router", "/codec_manager"],
              }
            : {
                acl: [
                  {
                    name: "/admin",
                    scopes: ["Elixir.show", "Elixir.update"],
                  },
                ],
                groups: ["/admin"],
              },
        start_outbound_process: true,
        tag: "swarm_h20_gateway",
        class: "gateway",
        coordinates: {
          coordinates: [formData.coordinates.lng, formData.coordinates.lat],
          type: "Point",
        },
        name: formData.name,
        serial_number: formData.serial_number,
        street_address: formData.street_address,
        iccid: formData.iccid && formData.iccid,
        mac: formData.mac,
        area: formData.areas,
        tx_chain: formData.chain,
      },
      meta_data: {},
    };

    createGateway({
      id: digitalTwinId,
      attributes: attributes,
    })
      .unwrap()
      ?.then((data) => setCreatedId(data?.data?.id))
      ?.catch((error) => {
        console.error("Error occurred:", error);
      });

    if (digitalTwinName == "LoraWan Gateway") {
      const attributes = {
        values: {
          command: "create_gateway",
          content: {
            mac: formData.mac,
            area: formData.areas,
            tx_chain: formData.chain,
          },
        },
      };
      postCommands({
        attributes: attributes,
        commandName: "configure",
        digitalTwinId: window.LORA,
        digitalTwinInstance: loraMeterId,
      });
    }
  };
  useEffect(() => {
    if (isSuccess && createdId) {
      navigate(`/gateways/${createdId}`);
    }
  }, [isSuccess, createdId]);
  useEffect(() => {}, [formData]);
  useEffect(() => {
    if (digitalTwinName === "LoraWan Gateway") {
      const props = { ...schm.properties };
      const req = [...defaultrequired];
      const required = [...new Set(req)];
      props["lora"] = {
        mapFn: (result) =>
          result?.data?.data?.map((item) => ({
            key: item?.id,
            label: `${item.attributes?.fields?.name} `,
            value: `${item.attributes?.fields?.name}`,
          })),

        url: "altior/device?tag=lorawan_server",
        type: "string",
        title: t("lora"),
        lora: test,
      };
      props["mac"] = {
        type: "string",
        title: t("MAC"),
        readOnly: loraAreas ? false : true,
      };
      props["chain"] = {
        type: "string",
        title: t("tx_chain"),
        readOnly: loraAreas ? false : true,
      };
      props["areas"] = {
        type: "string",
        title: t("areas"),
        oneOf: loraAreas && mappedAreas.length > 0 ? [...mappedAreas] : [],
        readOnly: loraAreas ? false : true,
      };

      const newSchema = { ...schema, properties: props, required };
      setSchema(newSchema);
    } else {
      setSchema(schm);
    }
  }, [digitalTwinName, security, loraAreas, mappedAreas]);

  useEffect(() => {
    console.log("fmdt4k", formData);
  }, [formData]);

  useEffect(() => {
    if (identifier) {
      const props = { ...schm.properties };
      const req = [...defaultrequired];
      const required = [...new Set(req)];
      props[identifier] = {
        title: t(identifier),
        type: "string",
      };
      const newSchema = { ...schema, properties: props, required };
      setSchema(newSchema);
      const order = { ...uiSchema };
      order["ui:order"] = [
        "digital_twin",
        "name",
        "security",
        "serial_number",

        "street_address",
        "msisdn",
        "time_zone",
        "coordinates",
        identifier,
        "lora",
        "mac",
        "chain",
        "areas",
      ];
      const newUiSchema = { ...uiSchema, ...order };

      setUiSchema(newUiSchema);
    } else {
      setSchema(schm);
    }
  }, [identifier]);

  useEffect(() => {
    if (digitalTwinName !== "LoraWan Gateway" && formData.lora) {
      const data = formData;
      delete data.lora;
      delete data.chain;
      delete data.mac;
      delete data.areas;
      setFormData(data);
      dispatch(setLoraAreas(null));
    }
  }, [digitalTwinName]);
  useEffect(() => {}, [digitalTwinName]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4">{t("new_gateway")}</Typography>
      <Box sx={{ mt: 2 }}>
        <Form
          schema={schema}
          fields={fields}
          formData={formData}
          onChange={(changeEvent) => setFormData(changeEvent.formData)}
          onSubmit={(values) => onSubmitClick(values.formData)}
          validator={validator}
          uiSchema={uiSchema}
          /* widgets={widgets} */
          showErrorList={false}
          /*   customValidate={customValidate} */
          noHtml5Validate
        />
      </Box>
    </Box>
  );
};
export default GatewayCreate;

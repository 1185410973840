import WidgetMapContainer from "./utils/WidgetMapContainer";
import { store } from "../../../redux/store";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useTheme,
  Paper,
  Box,
  Divider,
  IconButton,
  Grid,
  Chip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  setDragBlcok,
  deleteCreatedWidget,
} from "../../../redux/slices/dashboardSlice";
import PopoverCardTitle from "../PopoverCardTitle";
import { selectLabel } from "./utils/widgetConfigurations";

const MapWidget = ({
  widget,
  edit,
  setTrashModal,
  setTrashId,
  creationPhase,
}) => {
  const [getByName, resp, lpi] = store.useLazyGetDataByNameQuery();
  const dispatch = useDispatch();
  const theme = useTheme();
  const previewMode = useSelector((state) => state?.widgetsSlice?.previewMode);

  useEffect(() => {
    getByName(["altior/device"]);
  }, []);

  const mapCoordinates = resp?.data?.data
    ?.map((item) => item?.attributes?.fields?.coordinates)
    ?.map((item) => item?.coordinates);

  const onTrashClick = (id) => {
    console.log("tresha4ok");
    setTrashModal(true);
    setTrashId(id);
  };

  const onDelete = (id) => {
    dispatch(deleteCreatedWidget(id));
  };

  return (
    <Paper
      elevation={5}
      sx={{
        width: "100%",
        height: 520,
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
        paddingBottom: 2,
        paddingTop: 2,
      }}
    >
      <Box sx={{ mb: 1 }}>
        <PopoverCardTitle
          title={widget?.name}
          description={widget?.description}
          edit={edit}
        />
        <Divider />
        {edit && (
          <IconButton sx={{ float: "right" }}>
            <DeleteIcon
              onMouseEnter={() => dispatch(setDragBlcok(true))}
              onMouseLeave={() => dispatch(setDragBlcok(false))}
              onClick={
                creationPhase
                  ? () => onDelete(widget?.id)
                  : () => onTrashClick(widget?.id)
              }
              sx={{
                float: "right",
                fill: theme.palette.error.main,
                cursor: "pointer",
              }}
            />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          alignItems: "center",
          justifyContent: "center",
          width: "100%", // чтобы содержимое занимало всю ширину родителя
        }}
      >
        <WidgetMapContainer
          pos={mapCoordinates}
          hideAccordion={false}
          setPreferenceLoading={() => {}}
          params={{}}
          style={{ width: "100%" }} // чтобы карта тоже занимала всю ширину
        />
      </Box>
    </Paper>
  );
};

export default MapWidget;

import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import { store } from "../redux/store";
import { useTranslation } from "react-i18next";

import { useTheme } from "@mui/material";
import { Typography } from "@mui/material";
function not(a, b) {
  return a?.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a?.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList({ allRoles, userRoles, userId, grantId }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [changeUserRoles, { isSuccess }] = store.useChangeUserRolesMutation();
  const [getUserShow] = store.useLazyGetUserShowQuery();
  const [getGrant] = store.useLazyGetGrantQuery();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(
    userRoles ? userRoles?.map((item) => item) : null
  );
  const [right, setRight] = React.useState(
    allRoles ? allRoles?.map((item) => item) : null
  );
  const onSaveClick = () => {
    const attributes = {
      roles: left,
    };

    changeUserRoles({
      attributes: attributes,
      userId: userId,
      grantId: grantId,
    });
  };
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };
  React.useEffect(() => {
    if (isSuccess) {
      getUserShow(userId);
      getGrant(userId);
    }
  }, [isSuccess]);
  const customList = (items) => (
    <Paper sx={{ width: "26vw", height: 230, overflow: "auto" }}>
      <List dense component="div" role="list">
        {items?.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} secondary={`${value}`} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>
        {
          <Box>
            <Typography>{t("associated_roles")}</Typography>
            <Box sx={{ mt: 1 }}> {customList(left)}</Box>
          </Box>
        }
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left?.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked?.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked?.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right?.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
          <Button
            onClick={onSaveClick}
            variant={window.BUTTON_TYPE}
            sx={{
              mt: 1,
            }}
          >
            {t("save")}
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        {
          <Box>
            <Typography>{t("not_associated_roles")}</Typography>
            <Box sx={{ mt: 1 }}> {customList(right)}</Box>
          </Box>
        }
      </Grid>
    </Grid>
  );
}

import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import { Link } from "react-router-dom";
import theme from "../../theme";
import { TextField, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { useTranslation } from "react-i18next";
import { setCommandTrashId } from "../../redux/slices/meterSlice";
import IwdDataGrid from "../../pages/IwdDataGrid";
import DraftsIcon from "@mui/icons-material/Drafts";
import { clearCommandShow } from "../../redux/slices/meterSlice";
import { setSeverity } from "../../redux/slices/snackbarSlice";
import { store } from "../../redux/store";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControl from "@mui/material/FormControl";
import { useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import ModalDialog from "../meters/ModalDialog";
import Select from "@mui/material/Select";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { TextareaAutosize } from "@mui/base";

const MeterCommands = ({ digitalTwinId }) => {
  const commands = useSelector((state) => state?.meterSlice?.commands)
    ?.map((item) => ({
      label: item?.attributes?.name,
      type: item?.attributes?.type,
    }))
    ?.filter((cmd) => !window?.DISABLED_COMMANDS?.includes(cmd.label));
  const commandIndex = useSelector((state) => state?.meterSlice?.commandIndex);
  const commandTrashId = useSelector(
    (state) => state?.meterSlice?.commandTrashId
  );
  const [abortCommand, { isSuccess: abortSuccess, isError: abortErrror }] =
    store.useDeleteCommandMutation();
  const [trashModal, setTrashModal] = React.useState(false);
  const [trashId, setTrashId] = React.useState(null);
  const { t } = useTranslation();
  const gatewayTwinId = useSelector((state) => state?.gatewaySlice?.twinId);
  const [page, setPage] = React.useState(1);
  const [sendCommands, setSendCommands] = React.useState(false);
  const [getCommands] = store.useLazyGetMeterAttributesQuery();
  const [modalMessage, setModalMessage] = React.useState(false);
  const [getCommandShow] = store.useLazyGetShowCommandQuery();
  const [getCommandIndex] = store.useLazyGetCommandIndexQuery();
  const attributes = useSelector((state) => state?.meterSlice?.commands);
  const message = useSelector((state) => state?.meterSlice?.commandShow);
  const [postCommands, { isSuccess: postSuccess }] =
    store.usePostCommandsMutation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [command, setCommand] = React.useState("");
  const [commandValue, setCommandValue] = React.useState("");
  const smallField =
    command?.label === "set_valve_angle" ||
    command?.label == "set_valve_angle_unsafe";
  const handleChange = (e) => {
    setCommand(e.target.value);
  };
  const handleValueChange = (e) => {
    setCommandValue(e.target.value);
  };
  React.useEffect(() => {
    if (postSuccess) {
      dispatch(setSeverity("success"));
      dispatch(setMessage(t("demo_succ_msg")));
      getCommandIndex(id);
      setSendCommands(false);
    }
  }, [postSuccess]);
  const validateJson = (obj) => {
    if (command?.type !== "void") {
      try {
        const jsonObject = JSON.parse(obj);
        const attributes = {
          values: JSON.parse(commandValue),
        };
        postCommands({
          attributes: attributes,
          commandName: command?.label,
          digitalTwinId: digitalTwinId ?? gatewayTwinId,
          digitalTwinInstance: id,
        });
      } catch (error) {
        console.error("Invalid JSON:", error.message);
        dispatch(setSeverity("warning"));
        dispatch(setMessage("JSON NOT VALID"));
      }
    } else {
      const attributes = {
        values: {},
      };
      postCommands({
        attributes: attributes,
        commandName: command?.label,
        digitalTwinId: digitalTwinId ?? gatewayTwinId,
        digitalTwinInstance: id,
      });
    }
  };

  React.useEffect(() => {
    if (commandTrashId) {
      abortCommand({
        meterId: id,
        commandId: commandTrashId,
      });
    }
  }, [commandTrashId]);

  React.useEffect(() => {
    if (abortErrror) {
      dispatch(setCommandTrashId(null));
      setTrashId(null);
    }
  }, [abortErrror]);

  React.useEffect(() => {
    if (abortSuccess) {
      dispatch(setCommandTrashId(null));
      setTrashId(null);
      dispatch(setMessage(t("command_aborted")));
      getCommandIndex(id);
    }
  }, [abortSuccess]);

  const integerType = () => {
    const attributes = {
      values: commandValue,
    };
    postCommands({
      attributes: attributes,
      commandName: command?.label,
      digitalTwinId: digitalTwinId ?? gatewayTwinId,
      digitalTwinInstance: id,
    });
  };
  const onSubmitClick = () => {
    validateJson(commandValue);
  };
  React.useEffect(() => {
    getCommands(digitalTwinId ?? gatewayTwinId);
    getCommandIndex(id);
  }, [digitalTwinId]);

  React.useEffect(() => {
    console.log("comindex", commandIndex);
  }, [commandIndex]);

  const onDeleteClick = (id) => {
    setTrashModal(true);
    setTrashId(id);
  };
  const onMailClick = (id, commandId) => {
    setModalMessage(true);
    getCommandShow({
      meterId: id,
      commandId: commandId,
    });
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "command",
      headerName: t("command"),
      sortable: true,
      flex: 4,
    },
    {
      field: "status",
      headerName: t("status"),
      sortable: true,
      flex: 4,
    },
    {
      field: "inserted_at",
      headerName: t("inserted_at"),
      sortable: true,
      flex: 4,
    },
    {
      field: "updated_at",
      headerName: t("updated_at"),
      sortable: true,
      flex: 4,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <>
              <IconButton
                sx={{ float: "right" }}
                onClick={() => onDeleteClick(params?.value)}
              >
                <DoNotDisturbOnIcon
                  sx={{ ml: 1, fill: theme.palette.error.main }}
                />
              </IconButton>

              <IconButton
                onClick={() => onMailClick(id, params?.value)}
                sx={{
                  float: "right",

                  fill: theme.palette.navIcon,
                }}
              >
                <DraftsIcon
                  sx={{
                    fill: theme.palette.navIcon,
                  }}
                />
              </IconButton>
            </>
          </Box>
        );
      },
    },
  ];

  const rows = commandIndex?.map((item) => ({
    id: item?.id,
    command: item?.attributes?.command,
    status: item?.attributes?.status,
    inserted_at: t("dt", { val: new Date(item?.attributes?.inserted_at) }),
    updated_at: item?.attributes?.updated_at
      ? t("dt", { val: new Date(item?.attributes?.updated_at) })
      : "--",
    actions: item?.id,
  }));

  const onModalClose = () => {
    setModalMessage(false);
    dispatch(clearCommandShow());
  };

  return (
    <>
      <ModalDialog
        open={modalMessage && message}
        close={onModalClose}
        title={" "}
      >
        <Box>
          <Typography variant="subtitle2">
            <pre
              style={{
                overflow: "auto",
                background: "#c0c0c0",
                padding: "9px",
              }}
            >
              {JSON.stringify(
                (() => {
                  if (!message) return {};
                  const { identifier, key, ...rest } = message;
                  return rest;
                })(),
                null,
                10
              )}
            </pre>
          </Typography>
        </Box>
      </ModalDialog>
      <DeleteConfirmModal
        trashModal={trashModal}
        setTrashModal={setTrashModal}
        text={t("abort_command_text")}
        title={t("abort_command_title")}
        deleteFunc={() => setCommandTrashId(trashId)}
      />
      {commandIndex && !sendCommands && (
        <Box>
          <IwdDataGrid
            rows={rows}
            columns={columns}
            perPage={10}
            setPage={setPage}
            page={page}
            count={rows?.length}
            sort={false}
            height={"60vh"}
            tableName={"meter_commands"}
            paginationMode={"client"}
          />
          <Button
            sx={{ float: "right", mt: 2 }}
            onClick={() => setSendCommands(true)}
          >
            {t("send_new_command")}
          </Button>
        </Box>
      )}
      {commands && sendCommands && (
        <Box sx={{ mt: 4, height: "58vh" }}>
          <Autocomplete
            fullWidth
            disablePortal
            value={command}
            onChange={(event, newValue) => {
              setCommand(newValue);
            }}
            id="combo-box-demo"
            options={commands ?? []}
            renderInput={(params) => <TextField {...params} label="Command" />}
          />
          <Box
            sx={{
              height: smallField ? "8vh" : "48vh",
              overflowY: "auto",
              marginTop: 5,
            }}
          >
            {command?.label === "set_valve_angle" ||
            command?.label == "set_valve_angle_unsafe" ? (
              <TextField
                value={commandValue}
                onChange={(e) => handleValueChange(e)}
                fullWidth
                type="number"
                error={commandValue > 100}
                helperText={commandValue > 100 && t("percent_error")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
              />
            ) : (
              <>
                {command?.type !== "void" && (
                  <TextareaAutosize
                    value={commandValue}
                    disabled={command?.type === "void"}
                    minRows={30}
                    onChange={(e) => handleValueChange(e)}
                    style={{
                      width: "100%",
                      marginTop: 5,

                      resize: "none",
                    }}
                    label="Command value"
                  />
                )}
              </>
            )}
          </Box>
          <Button onClick={() => setSendCommands(false)}>{t("back")}</Button>
          <Button
            disabled={!command || (smallField && commandValue > 100)}
            onClick={onSubmitClick}
            sx={{ float: "right" }}
            variant={window.BUTTON_TYPE}
          >
            {t("send_command")}
          </Button>
        </Box>
      )}
    </>
  );
};
export default MeterCommands;

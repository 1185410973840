import { Box, Typography, Button } from "@mui/material";
import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import MeterDatePicker from "../form/formComponents/MeterDatePicker";
import SingleDatePicker from "../form/formComponents/SingleDatePicker";
import { useState, useEffect } from "react";
import PolicyTranser from "./PolicyTranser";
import BalanceTransferList from "../../pages/balances/BalanceTransferList";
import SearchOnServer from "../form/formComponents/SearchOnServer";
import UserWidget from "../users/UserWidget";
const fullRowNames = ["description", "roles", "devices_ids", "insertion_date"];

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        if (fullRowNames?.includes(element.name)) {
          return (
            <Grid key={element.id} size={12}>
              {element.content}
            </Grid>
          );
        }

        return (
          <Grid key={element.id} alignItems="center" size={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const refresh_rate_enum = [
  { const: 1 },
  { const: 2 },
  { const: 3 },
  { const: 4 },
];
const max_period_enum = [
  { const: 1 },
  { const: 2 },
  { const: 3 },
  { const: 4 },
  { const: 5 },
];

const PolicyCreate = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});

  const schema = {
    title: "New Device",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: t("name"),
      },
      user: {
        type: "string",
        title: t("user"),
      },
      meter_refresh_rate: {
        type: "integer",
        title: t("meter_refresh_rate"),
        oneOf: [...refresh_rate_enum],
      },
      close_percentage: {
        type: "integer",
        title: t("close_percentage"),
      },
      max_period: {
        type: "integer",
        title: t("max_period"),
        oneOf: [...max_period_enum],
      },
      consumption_treshhold: {
        type: "string",
        title: t("consumption_treshhold"),
      },
      automatic_closing: {
        type: "string",
        title: t("automatic_closing"),
        enum: ["true", "false"],
        enumNames: [t("yes"), t("no")],
      },
      applies_to_holidays: {
        type: "string",
        title: t("applies_to_holidays"),
        enum: ["true", "false"],
        enumNames: [t("yes"), t("no")],
      },
      insertion_date: {
        title: t("inserted_at"),
        type: "object",
        properties: {
          from: {
            type: "string",
          },
          to: {
            type: "string",
          },
        },
      },

      roles: {
        type: "array",
        title: t("roles"),
        roles: true,
        multiple: true,
        url: "role",
      },
      description: {
        type: "string",
        title: t("description"),
      },
      devices_ids: {
        type: "array",
        title: t("devices_ids"),
      },
    },
    required: [],
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    insertion_date: {
      "ui:field": "datepicker",
    },
    interrupted: {
      "ui:field": "SingleDatePicker",
    },
    description: {
      "ui:widget": "textarea",
    },
    roles: {
      "ui:field": "roles",
    },
    devices_ids: {
      "ui:field": "transfer",
    },
    user: {
      "ui:field": "user",
    },
    "ui:submitButtonOptions": {
      submitText: t("create"),

      props: {
        className: "bntListSearch",
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
  };
  const fields = {
    datepicker: MeterDatePicker,
    SingleDatePicker: SingleDatePicker,
    roles: SearchOnServer,
    transfer: PolicyTranser,
    user: UserWidget,
  };

  useEffect(() => {
    console.log("frmdatyara", formData);
  }, [formData]);
  useEffect(() => {
    if (formData?.close_percentage > 100) {
      setFormData({
        ...formData,
        close_percentage: 100,
      });
    }
  }, [formData?.close_percentage]);
  const customValidate = (formData, errors) => {
    if (!formData.insertion_date.from) {
      errors?.insertion_date?.from?.addError("is a required property");
    }
    if (!formData.insertion_date.to) {
      errors?.insertion_date?.to?.addError("is a required property");
    }
    if (formData.insertion_date.from >= formData.insertion_date.to) {
      errors.insertion_date.to.addError(t("date_error"));
    }

    return errors;
  };
  return (
    <Box>
      <Form
        schema={schema}
        fields={fields}
        formData={formData}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        onSubmit={(values) => console.log(values.formData)}
        validator={validator}
        uiSchema={uiSchema}
        customValidate={customValidate}
        showErrorList={false}
        noHtml5Validate
      />
    </Box>
  );
};
export default PolicyCreate;

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import { Box } from "@mui/material";
import AuthProvider from "./components/AuthProvider";

import { Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import Uploader from "./components/FileUploader /Uploader";
/* import store from "./redux/store"; */

import MaintenancePage from "./utils/MaintenancePage";
import GatewayIndex from "./components/gateway/GatewayIndex";
import CreateWidget from "./components/widgets/CreateWidget";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import ErrorAssociationIndex from "./components/meterErrors/ErrorAssociationIndex";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";

import AuthenticatedProvider from "./components/AuthenticatedProvider";

import theme from "./theme";
import GlobalLoading from "./utils/GlobalLoading";

import { ThemeProvider } from "@mui/material";

import CustomSnackbar from "./utils/Snackbars";
import DownloadCenter from "./utils/DownloadCenter";
import { createRoot } from "react-dom/client";

import HelmetHeader from "./utils/HelmetHeader";

import { setupStore } from "./redux/store";

import { IwdRoutes } from "./utils/Routes";
import { IwdRoute } from "./utils/IwdRoute";
const store = setupStore({});
function Ade() {
  // 👇️ redirect to external URL
  window.location.replace("http://192.168.1.65:9295/");

  return null;
}

function ProtectedPage() {
  return <h3>Protected</h3>;
}
library.add(fab, faCheckSquare, faCoffee);

const container = document.getElementById("root");
const root = createRoot(container);
function fallbackRender({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}
root.render(
  <Provider store={store}>
    <HelmetHeader />
    <React.StrictMode>
      <Suspense /* fallback={<FallbackLoader />} */>
        <GlobalLoading />
        <CustomSnackbar />
        <DownloadCenter />
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <AuthenticatedProvider>
              <Box>
                <BrowserRouter>
                  <Routes>
                    <Route element={<Layout />}>
                      {!window.MAINTENANCE
                        ? IwdRoutes?.map((item) => (
                            <Route
                              path={item?.path}
                              element={
                                <IwdRoute
                                  auth={item?.auth}
                                  component={item?.component}
                                  section={item?.section}
                                />
                              }
                            />
                          ))
                        : IwdRoutes?.map((item) => (
                            <Route
                              path={item?.path}
                              element={
                                <IwdRoute
                                  auth={item?.auth}
                                  component={<MaintenancePage />}
                                  section={item?.section}
                                />
                              }
                            />
                          ))}
                    </Route>
                  </Routes>
                </BrowserRouter>
              </Box>
            </AuthenticatedProvider>
          </AuthProvider>
        </ThemeProvider>
      </Suspense>
    </React.StrictMode>
  </Provider>
);

reportWebVitals();

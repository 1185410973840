import React, { useState, useCallback, forwardRef, useEffect } from "react";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Grid } from "@mui/material";
import { selectType } from "../dashboard/widgets/utils/widgetConfigurations";
import theme from "../../theme";

// Исходные данные dashboard (неизменяемый массив)
const initialDashboard = [
  {
    description: "Overview oggetti in errore",
    groups: ["admin", "/admin"],
    id: 67,
    inserted_at: "2024-09-09 15:56:00.737034Z",
    last_error: {
      data: "NA",
      date: null,
    },
    last_update: {
      data: [
        {
          name: "Oggetti in errore",
          value: 0,
        },
        {
          name: "Oggetti non in errore",
          value: 100,
        },
      ],
      date: "2024-10-30 16:27:14.504983Z",
    },
    link: null,
    name: "Overview oggetti in errore",
    refresh_rate: 3600000,
    statistic_id: 67,
    statistics: {
      __cardinality__: "one",
      __field__: "statistics",
      __owner__: "Elixir.Metrics.Widgets.Widget",
    },
    tags: ["condominium"],
    type: "pie_chart",
    updated_at: "2024-09-09 15:56:00.737034Z",
  },
  {
    description: "Oggetti in errore",
    groups: ["admin", "/admin"],
    id: 65,
    inserted_at: "2024-09-09 15:56:00.727373Z",
    last_error: {
      data: "NA",
      date: null,
    },
    last_update: {
      data: [
        {
          count: 0,
        },
      ],
      date: "2024-10-30 16:27:14.580512Z",
    },
    link: null,
    name: "Oggetti in errore",
    refresh_rate: 3600000,
    statistic_id: 65,
    statistics: {
      __cardinality__: "one",
      __field__: "statistics",
      __owner__: "Elixir.Metrics.Widgets.Widget",
    },
    tags: ["condominium"],
    type: "single_value",
    updated_at: "2024-09-09 15:56:00.727373Z",
  },
  {
    description: "mappa",
    groups: ["admin", "/admin"],
    id: 69,
    inserted_at: "2024-10-16 14:29:34.558887Z",
    last_error: {
      data: "NA",
      date: null,
    },
    last_update: {
      data: null,
      date: null,
    },
    link: null,
    name: "Map",
    refresh_rate: 30000000,
    statistic_id: 59,
    statistics: {
      __cardinality__: "one",
      __field__: "statistics",
      __owner__: "Elixir.Metrics.Widgets.Widget",
    },
    tags: ["device"],
    type: "map",
    updated_at: "2024-10-16 14:36:19.612237Z",
  },
  {
    description: "Oggetti non contatori in errore",
    groups: ["admin", "/admin"],
    id: 66,
    inserted_at: "2024-09-09 15:56:00.731593Z",
    last_error: {
      data: "NA",
      date: null,
    },
    last_update: {
      data: [
        {
          count: 0,
        },
      ],
      date: "2024-10-30 16:26:57.757030Z",
    },
    link: null,
    name: "Oggetti non contatori in errore",
    refresh_rate: 3600000,
    statistic_id: 66,
    statistics: {
      __cardinality__: "one",
      __field__: "statistics",
      __owner__: "Elixir.Metrics.Widgets.Widget",
    },
    tags: ["condominium"],
    type: "single_value",
    updated_at: "2024-09-09 15:56:00.731593Z",
  },
  {
    description: "Overview errori contatori 24",
    groups: ["admin", "/admin"],
    id: 56,
    inserted_at: "2024-09-09 15:56:00.689462Z",
    last_error: {
      data: "NA",
      date: null,
    },
    last_update: {
      data: [
        {
          name: "Contatori in errore",
          value: 0,
        },
        {
          name: "Totale contatori non in errore",
          value: 100,
        },
      ],
      date: "2024-10-30 16:26:57.748328Z",
    },
    link: null,
    name: "Overview errori contatori 24",
    refresh_rate: 3600000,
    statistic_id: 56,
    statistics: {
      __cardinality__: "one",
      __field__: "statistics",
      __owner__: "Elixir.Metrics.Widgets.Widget",
    },
    tags: ["device"],
    type: "pie_chart",
    updated_at: "2024-09-09 15:56:00.689462Z",
  },
  {
    description: "Totale oggetti",
    groups: ["admin", "/admin"],
    id: 37,
    inserted_at: "2024-09-09 15:56:00.573626Z",
    last_error: {
      data: "NA",
      date: null,
    },
    last_update: {
      data: [
        {
          count: 25,
        },
      ],
      date: "2024-10-30 16:26:57.742973Z",
    },
    link: null,
    name: "Totale oggetti",
    refresh_rate: 3600000,
    statistic_id: 37,
    statistics: {
      __cardinality__: "one",
      __field__: "statistics",
      __owner__: "Elixir.Metrics.Widgets.Widget",
    },
    tags: ["condominium"],
    type: "single_value",
    updated_at: "2024-09-09 15:56:00.573626Z",
  },
  {
    description: "Totale contatori acqua calda",
    groups: ["admin", "/admin"],
    id: 40,
    inserted_at: "2024-09-09 15:56:00.600952Z",
    last_error: {
      data: "NA",
      date: null,
    },
    last_update: {
      data: [
        {
          count: 10,
        },
      ],
      date: "2024-10-30 16:26:57.718525Z",
    },
    link: null,
    name: "Totale contatori ACS",
    refresh_rate: 3600000,
    statistic_id: 40,
    statistics: {
      __cardinality__: "one",
      __field__: "statistics",
      __owner__: "Elixir.Metrics.Widgets.Widget",
    },
    tags: ["device"],
    type: "single_value",
    updated_at: "2024-09-09 15:56:00.600952Z",
  },
  {
    description: "Overview tipologia oggetti",
    groups: ["admin", "/admin"],
    id: 45,
    inserted_at: "2024-09-09 15:56:00.637177Z",
    last_error: {
      data: "NA",
      date: null,
    },
    last_update: {
      data: [
        {
          name: "AFS",
          value: 56,
        },
        {
          name: "Calorie",
          value: 4,
        },
        {
          name: "ACS",
          value: 40,
        },
      ],
      date: "2024-10-30 16:20:18.219541Z",
    },
    link: null,
    name: "Overview tipologia oggetti",
    refresh_rate: 3600000,
    statistic_id: 45,
    statistics: {
      __cardinality__: "one",
      __field__: "statistics",
      __owner__: "Elixir.Metrics.Widgets.Widget",
    },
    tags: ["condominium"],
    type: "line_chart",
    updated_at: "2024-10-30 09:20:13.076088Z",
  },
  {
    description:
      "Gateways visti nelle ultime 24 ore (dalla mezza notte di oggi)",
    groups: ["admin", "/admin"],
    id: 47,
    inserted_at: "2024-09-09 15:56:00.647180Z",
    last_error: {
      data: "NA",
      date: null,
    },
    last_update: {
      data: [
        {
          count: 0,
        },
      ],
      date: "2024-10-30 16:26:57.733319Z",
    },
    link: null,
    name: "Gateways visti nelle ultime 24 ore",
    refresh_rate: 3600000,
    statistic_id: 47,
    statistics: {
      __cardinality__: "one",
      __field__: "statistics",
      __owner__: "Elixir.Metrics.Widgets.Widget",
    },
    tags: ["network"],
    type: "single_value",
    updated_at: "2024-09-09 15:56:00.647180Z",
  },
];

const Item = forwardRef(
  ({ id, withOpacity, isDragging, children, style, ...props }, ref) => {
    const inlineStyles = {
      cursor: isDragging ? "grabbing" : "grab",
      ...style,
    };

    return (
      <div ref={ref} style={inlineStyles} {...props}>
        {children}
      </div>
    );
  }
);

const SortableItem = ({ id, children }) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition: transition || undefined,
  };

  return (
    <Item
      ref={setNodeRef}
      style={style}
      withOpacity={isDragging}
      {...attributes}
      {...listeners}
    >
      {children}
    </Item>
  );
};

const RectangleContainer = () => {
  const [widgets, setWidgets] = useState(() => {
    // Загружаем виджеты из localStorage или используем исходные данные
    const savedWidgets = localStorage.getItem("widgets");
    return savedWidgets ? JSON.parse(savedWidgets) : initialDashboard;
  });

  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = useCallback((event) => {
    setActiveId(event.active.id);
  }, []);

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setWidgets((prevWidgets) => {
        const oldIndex = prevWidgets.findIndex(
          (widget) => widget.id === active.id
        );
        const newIndex = prevWidgets.findIndex(
          (widget) => widget.id === over.id
        );
        const updatedWidgets = arrayMove(prevWidgets, oldIndex, newIndex);

        // Сохраняем обновленный порядок виджетов в localStorage
        localStorage.setItem("widgets", JSON.stringify(updatedWidgets));
        return updatedWidgets;
      });
    }
    setActiveId(null);
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  const selectWidgetDimension = (type) => {
    switch (type) {
      case "pie_chart":
      case "line_chart":
        return 4;
      case "map":
        return 12;
      default:
        return 2;
    }
  };

  useEffect(() => {
    console.log("widuhi", widgets);
  }, [widgets]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      isDragging={false}
    >
      <SortableContext items={widgets.map((widget) => widget.id)}>
        <Grid container spacing={3} sx={{ marginTop: 4 }}>
          {widgets.map((widget) => (
            <Grid
              item
              xs={selectWidgetDimension(widget?.type)}
              key={widget?.id}
            >
              <SortableItem id={widget?.id}>
                {selectType(widget, false, false, false)}
              </SortableItem>
            </Grid>
          ))}
        </Grid>
      </SortableContext>
      <DragOverlay>
        {activeId ? (
          <Item id={activeId} isDragging>
            {selectType(
              widgets.find((widget) => widget.id === activeId),
              false,
              false,
              false
            )}
          </Item>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default RectangleContainer;

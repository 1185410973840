import { createSlice } from "@reduxjs/toolkit";
import { store } from "../store";

const initialState = {
  channelName: null,
  channelParams: null,
  dataFunction: null,
  hiden: null,
  eventName: null,
  manualClean: null,
};

const dwCenterSllice = createSlice({
  name: "dwCenterSllice",
  initialState,
  reducers: {
    setWsParams(state, action) {
      state.channelName = action.payload.channelName;
      state.channelParams = action.payload.channelParams;
      state.dataFunction = action.payload.dataFunction;
      state.hiden = action.payload.hiden;
      state.eventName = action.payload.eventName;
      state.manualClean = action.payload.manualClean;
    },
    resetWsParams(state) {
      state.channelName = null;
      state.channelParams = null;
      state.dataFunction = null;
      state.hiden = null;
      state.eventName = null;
      state.manualClean = null;
    },
  },
});
export const dwCenterActions = dwCenterSllice.actions;
export default dwCenterSllice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { setErrorType } from "../../utils/api_params";
const initialState = {
  showError: false,
  data: [],
  errors: [],
  errorTitle: "",
  errorStatus: "",
  error: null,
  errorType: null,
  errorRedirect: false,
};

const errorSlice = createSlice({
  name: "errorSlice",
  initialState,
  reducers: {
    setErrors(state, action) {
      if (
        action.payload.status == 404 &&
        action.payload.url?.includes("dashboards")
      ) {
        return;
      }

      if (
        action.payload.error !== "AbortError: The user aborted a request." &&
        action.payload.error !== "Channel not valid" &&
        action.payload.error !== "Params not valid" &&
        action.payload.endpoint !== "login"
      ) {
        if (typeof action.payload.data === "object") {
          if (
            action.payload.data.errors &&
            action.payload.data.errors.detail &&
            action.payload.data.errors.detail.body &&
            action.payload.data.errors.detail.body?.includes("Password")
          ) {
            state.data = ["pass_err"];
          } else {
            state.data = Object?.entries(action?.payload?.data?.errors)?.map(
              (item) => `${item?.[0]}: ${item?.[1]}`
            );
          }
        }
        if (Array?.isArray(action?.payload?.data?.errors)) {
          state.data = action.payload.data.errors?.map((item) => item.title);
        }

        state.errors = action.payload.error;
        state.errorStatus = action.payload.originalStatus;
        state.errorTitle = action.payload.status;
        state.showError = true;
        state.errorType = setErrorType(action.payload.endpoint);
        state.errorRedirect = true;
      }
    },
    setError(state, action) {
      state.error = action.payload;
    },
    errorRedirectOff(state) {
      state.errorRedirect = false;
    },
    clearErrors(state) {
      state.errors = [];
      state.errorTitle = "";
      state.errorStatus = "";
      state.data = [];
      state.showError = false;
      state.errorType = null;
      state.errorRedirect = false;
    },
  },
});
export const { setErrors, clearErrors, setError, errorRedirectOff } =
  errorSlice.actions;
export default errorSlice.reducer;

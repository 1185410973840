import { Grid, Typography, useTheme, Paper, IconButton } from "@mui/material";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import SingleValuewidget from "../SingleValueDashboard";
import PieCharWidget from "../PieChartWidget";
import MapWidget from "../MapWidget";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import NetworkCellIcon from "@mui/icons-material/NetworkCell";
import HomeIcon from "@mui/icons-material/Home";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import GroupIcon from "@mui/icons-material/Group";
import Tooltip from "@mui/material/Tooltip";
import LineCharWidget from "../LineChartWidget";
export const selectColor = (tagType, theme) => {
  if (tagType === "network") {
    return theme?.palette.network.main;
  }
  if (tagType === "device") {
    return theme?.palette.device.main;
  }
  if (tagType === "group_and_district") {
    return theme?.palette.group_and_district.main;
  }
};

export const selectTextColor = (tagType, theme) => {
  if (tagType === "network") {
    return theme?.palette.network.main;
  }
  if (tagType === "device") {
    return theme?.palette.network.main;
  }
  if (tagType === "group_and_district") {
    return theme?.palette.network.main;
  }
  if (tagType === "condominium") {
    return theme?.palette.network.main;
  }
};

export const selectLabel = (tagType, theme) => {
  if (tagType === "network") {
    return (
      <Tooltip title="Network">
        <RssFeedIcon sx={{ fill: theme.palette.chipLabel }} />
      </Tooltip>
    );
  }
  if (tagType === "device") {
    return (
      <Tooltip title="Device">
        <DeviceHubIcon sx={{ fill: theme.palette.chipLabel }} />
      </Tooltip>
    );
  }
  if (tagType === "condominium") {
    return (
      <Tooltip title="Condominium">
        <HomeIcon sx={{ fill: theme.palette.chipLabel }} />
      </Tooltip>
    );
  }
  if (tagType === "group_and_district") {
    return (
      <Tooltip title="Group and district">
        <GroupIcon sx={{ fill: theme.palette.chipLabel }} />
      </Tooltip>
    );
  }
};

export const selectType = (
  widget,
  edit,
  setTrashModal,
  setTrashId,
  creationPhase
) => {
  if (widget.type === "single_value") {
    return (
      <div key={widget?.id}>
        <SingleValuewidget
          key={widget?.id}
          widget={widget}
          edit={edit}
          setTrashModal={setTrashModal}
          setTrashId={setTrashId}
          creationPhase={creationPhase}
        />
      </div>
    );
  }
  if (widget.type === "pie_chart" || widget.type === "multiple_value") {
    return (
      <div key={widget?.id}>
        <PieCharWidget
          key={widget?.id}
          widget={widget}
          edit={edit && edit}
          setTrashModal={setTrashModal}
          setTrashId={setTrashId}
          creationPhase={creationPhase}
        />
      </div>
    );
  }
  if (widget.type === "line_chart") {
    return (
      <div key={widget?.id}>
        <LineCharWidget
          key={widget?.id}
          widget={widget}
          edit={edit && edit}
          setTrashModal={setTrashModal}
          setTrashId={setTrashId}
          creationPhase={creationPhase}
        />
      </div>
    );
  }
  if (widget.type === "map") {
    return (
      <div key={widget?.id}>
        <MapWidget
          key={widget?.id}
          widget={widget}
          edit={edit && edit}
          setTrashModal={setTrashModal}
          setTrashId={setTrashId}
          creationPhase={creationPhase}
        />
      </div>
    );
  }
};

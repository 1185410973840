import * as React from "react";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormLabel,
  IconButton,
  FormGroup,
} from "@mui/material";
import { useState, useEffect } from "react";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { useSelector, useDispatch } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import DeselectIcon from "@mui/icons-material/Deselect";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useTranslation } from "react-i18next";
import { store } from "../../redux/store";
import WestIcon from "@mui/icons-material/West";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import EastIcon from "@mui/icons-material/East";
import { nameTransform } from "../../utils/utilsFunctions";
import MetersTableTab from "../form/formComponents/MetersTableTab";
import Table from "../form/formComponents/Table";
import Button from "@mui/material/Button";
import theme from "../../theme";
import IwdDataGrid from "../../pages/IwdDataGrid";
import { setBalancType } from "../../redux/slices/balanceSlice";
import { update } from "lodash";

const PolicyTranser = (props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const balance = useSelector((state) => state?.balanceSlice?.balance);
  const [getMeters] = store.useLazyGetMeterIndexQuery();
  const [addedSerialmeters, setSerialMeters] = useState([]);
  const [searchBy, setSearchBy] = useState("serial");
  const metersIndex = useSelector((state) => state?.meterSlice?.metersIndex);
  const [firstRender, setFirstRender] = useState(true);
  const metersIndexCount = useSelector(
    (state) => state?.meterSlice?.metersIndexCount
  );
  const [value, setValue] = useState(0);
  const [waterType, setWaterType] = useState("all");
  const { t } = useTranslation();
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const condominiumShow = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  );
  const parentmeters =
    useSelector((state) => state?.condominiumSlice?.condominiumShow)
      ?.children?.map((item) => item)
      ?.map((item) => item?.children)
      .flat()
      ?.map((item) => item?.children)
      .flat()
      ?.filter((item) => item?.attributes?.serial_number) ?? [];

  const impulsiveMeters =
    useSelector((state) => state?.condominiumSlice?.condominiumShow)
      ?.children?.map((item) => item.children)
      ?.flat()
      ?.map((item) => item.children)
      ?.flat()
      ?.map((item) => item?.children)
      .flat()
      ?.filter((item) => item?.attributes?.serial_number?.includes("-")) ?? [];

  let allMeters = [...parentmeters, ...impulsiveMeters];
  const balanceType = useSelector((state) => state?.balanceSlice?.type);
  const addSerialMeter = (newSerialMeter) => {
    setSerialMeters((prevMeters) => [...prevMeters, newSerialMeter]);
  };
  const addSerialMetersBatch = (newSerialMeters) => {
    setSerialMeters((prevMeters) => [...prevMeters, ...newSerialMeters]);
  };

  const removeSerialMeter = (serialMeterToRemove) => {
    setSerialMeters((prevMeters) =>
      prevMeters.filter(
        (meter) => meter?.attributes?.identifier !== serialMeterToRemove
      )
    );
  };
  useEffect(() => {
    if (condominiumShow) {
      allMeters = [...parentmeters, ...impulsiveMeters];
    }
  }, [condominiumShow, impulsiveMeters, parentmeters]);
  const waterBalance =
    (balanceType?.[0] == "AFS" && balanceType?.[1] == "ACS") ||
    balanceType?.[0] == "ACS" ||
    balanceType?.[0] == "AFS";
  const [selectAll, setSelectAll] = useState(false);
  const [metroSerial, setMetroSerial] = useState(null);
  const [idSetted, setIdSetted] = useState(false);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [originalMeters, setOriginalMeters] = React.useState([]);
  const [checkedItems, setCheckedItems] = React.useState({});
  const [inMeters, setInMeters] = useState([]);
  const [outMeters, setOutMeters] = useState([]);
  const handleCheckboxChange = (serial, checked) => {
    const selectedMeter = allMeters?.filter(
      (item) => item?.attributes?.serial_number == serial
    );
    setCheckedItems((prev) => ({
      ...prev,
      [serial]: checked,
    }));
    if (checked) {
      setSelectedItems([...selectedItems, selectedMeter[0]]);
    } else {
      setSelectedItems(
        selectedItems?.filter(
          (item) => item?.attributes.serial_number !== serial
        )
      );
    }
  };

  const isChecked = (serial) => !!checkedItems[serial];
  React.useEffect(() => {
    if (allMeters?.length > 0) {
      setOriginalMeters(allMeters);
    }
  }, [allMeters]);

  // Состояния для фильтров
  const [subBlock, setSubBlock] = React.useState(null);
  const [apartment, setApartment] = React.useState(null);
  const [serial, setSerial] = React.useState(null);

  const filterData = (data, filters) => {
    return data?.filter((item) => {
      return Object.keys(filters).every((key) => {
        if (!filters[key]) return true;
        return item?.attributes?.[key]
          ?.toLowerCase()
          ?.includes(filters[key]?.toLowerCase());
      });
    });
  };

  const [meters, setMeters] = React.useState([]);

  React.useEffect(() => {
    if (searchBy == "block") {
      const filteredData = filterData(originalMeters, {
        sub_condominium_name: subBlock,
        apartment_name: apartment,
        serial_number: serial,
      });
      setMeters(filteredData);
    } else {
    }
  }, [subBlock, apartment, serial, originalMeters]);

  useEffect(() => {
    if (props?.schema?.update) {
      const existsInMeters = allMeters?.filter((item) =>
        balance?.meta?.in_meters?.includes(item?.attributes?.identifier)
      );
      setInMeters([...existsInMeters]);
      const existsOutMeters = allMeters?.filter((item) =>
        balance?.meta?.out_meters?.includes(item?.attributes?.identifier)
      );
      setOutMeters([...existsOutMeters]);
      props.onChange({
        in_meters: existsInMeters?.map((item) => item?.attributes?.identifier),
        out_meters: existsOutMeters?.map(
          (item) => item?.attributes?.identifier
        ),
      });
      setIdSetted(true);
    } else {
      setIdSetted(true);
    }
  }, [props?.schema?.update]);

  const removeFromInMeters = (serial) => {
    setInMeters(
      inMeters?.filter((item) => item?.attributes?.serial_number !== serial)
    );
  };
  const removeFromOutMeters = (serial) => {
    setOutMeters(
      outMeters?.filter((item) => item?.attributes?.serial_number !== serial)
    );
  };
  React.useEffect(() => {}, [props]);
  const isItemSelected = (serial) => {
    selectedItems.findIndex((item) => item.attributes.serial_number == serial);
  };
  React.useEffect(() => {
    if (idSetted) {
      props.onChange({
        in_meters: props?.formData?.in_meters,
        out_meters: outMeters?.map((item) => item?.attributes?.identifier),
      });
    }
  }, [outMeters]);
  React.useEffect(() => {
    if (idSetted) {
      props.onChange({
        in_meters: inMeters?.map((item) => item?.attributes?.identifier),
        out_meters: props?.formData?.out_meters,
      });
    }
  }, [inMeters]);
  const columns = [
    {
      field: "subblock",
      headerName: t("subcondominum"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "apartment",
      headerName: t("apartament"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
    },
    /* {
      field: "room",
      headerName: t("room"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
    }, */
    {
      field: "serial",
      headerName: t("serial_number"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "type",
      headerName: t("type"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      headerAlign: "right",
      flex: 1,
      renderCell: (params) => {
        if (params.value?.mode == "all") {
          const isCheckedValue = isChecked(params.row.serial);
          return (
            <Box sx={{ width: "100%" }}>
              <Checkbox
                checked={isCheckedValue}
                onChange={(event) => {
                  handleCheckboxChange(
                    params.value.value,
                    event.target.checked
                  );
                }}
              />
            </Box>
          );
        }
        if (params?.value?.mode == "in") {
          return (
            <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
              <IconButton
                onClick={() => removeFromInMeters(params?.value?.value)}
              >
                <DeleteIcon sx={{ fill: theme.palette.error.main }} />
              </IconButton>
            </Box>
          );
        }
        if (params?.value?.mode == "out") {
          return (
            <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
              <IconButton
                onClick={() => removeFromOutMeters(params?.value?.value)}
              >
                <DeleteIcon sx={{ fill: theme.palette.error.main }} />
              </IconButton>
            </Box>
          );
        }
      },
    },
  ];

  const usedOutMeters = outMeters?.map(
    (item) => item?.attributes?.serial_number
  );
  const usedInMeters = inMeters?.map((item) => item?.attributes?.serial_number);
  const usedMeters = [...usedInMeters, ...usedOutMeters];
  React.useEffect(() => {}, [balanceType]);
  const handleSelectAll = () => {
    const filteredMeters = meters?.filter(
      (item) =>
        balanceType?.includes(item?.attributes?.meter_type) &&
        !usedMeters?.includes(item?.attributes?.serial_number)
    );

    const allChecked = filteredMeters.reduce((acc, item) => {
      acc[item.attributes.serial_number] = true;
      return acc;
    }, {});

    setCheckedItems(allChecked);

    setSelectedItems(filteredMeters);

    setSelectAll(true);
  };

  const handleUnselectAll = () => {
    setCheckedItems({});
    setSelectedItems([]);
    setSelectAll(false);
  };
  const inButtonDisable = () => {
    if (balanceType?.length == 1) {
      return selectedItems?.length > 0 ? false : true;
    }
    if (balanceType?.length == 2 && balanceType?.[0] === "Calorie") {
      return selectedItems?.length > 0 &&
        !selectedItems.some((item) => item?.attributes?.meter_type == "ACS")
        ? false
        : true;
    }
  };
  const outButtonDisable = () => {
    if (balanceType?.length == 1) {
      return selectedItems?.length > 0 ? false : true;
    }
    if (balanceType?.length == 2 && balanceType?.[0] === "Calorie") {
      return selectedItems?.length > 0 &&
        !selectedItems.some((item) => item?.attributes?.meter_type == "Calorie")
        ? false
        : true;
    }
  };

  const rows = meters
    ?.filter(
      (item) =>
        !usedMeters?.includes(item?.attributes?.serial_number) &&
        balanceType?.includes(item?.attributes?.meter_type)
    )
    ?.map((item, i) => ({
      id: i,
      subblock: item?.attributes?.sub_condominium_name,
      apartment: item?.attributes?.apartment_name,
      /*   room: item?.attributes?.room, */
      serial: item?.attributes?.serial_number,
      type: item?.attributes?.meter_type,
      actions: {
        value: item?.attributes?.serial_number,
        mode: "all",
      },
    }));
  const inMeterRows = inMeters?.map((item, i) => ({
    id: i,
    subblock: item?.attributes?.sub_condominium_name,
    apartment: item?.attributes?.apartment_name,
    /*     room: item?.attributes?.room, */
    serial: item?.attributes?.serial_number,
    type: item?.attributes?.meter_type,
    actions: {
      value: item?.attributes?.serial_number,
      mode: "in",
    },
  }));
  const outMeterRows = outMeters?.map((item, i) => ({
    id: i,
    subblock: item?.attributes?.sub_condominium_name,
    apartment: item?.attributes?.apartment_name,
    /*     room: item?.attributes?.room, */
    serial: item?.attributes?.serial_number,
    type: item?.attributes?.meter_type,
    actions: {
      value: item?.attributes?.serial_number,
      mode: "out",
    },
  }));
  const serialColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      hide: true,
    },

    {
      field: "class",
      headerName: t("class"),
      type: "number",
      sortable: false,
      headerAlign: "left",
    },
    {
      field: "network_address",
      headerName: t("network_address"),
      type: "string",
      nosort: true,
      editable: false,

      editable: false,
    },
    {
      field: "serial",
      headerName: t("metrological_serial"),
      type: "number",
      sortable: false,

      headerAlign: "left",
    },

    {
      field: "condominium_name",
      headerName: t("condominium"),
      type: "number",
      sortable: false,

      headerAlign: "left",
    },
    {
      field: "apartment_name",
      headerName: t("apartment_name"),

      sortable: false,

      headerAlign: "left",
    },

    /*  {
      field: "configdate",
      headerName: t("configuration_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "last_reeading_date",
      headerName: t("last_reeading_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    }, */
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            {params?.value?.variant == "add" && (
              <IconButton onClick={() => addSerialMeter(params?.value?.item)}>
                <AddIcon sx={{ fill: theme.palette.success.main }} />
              </IconButton>
            )}
            {params?.value?.variant == "delete" && (
              <IconButton
                onClick={() =>
                  removeSerialMeter(params?.value?.item?.attributes?.identifier)
                }
              >
                <RemoveCircleOutlineIcon
                  sx={{ fill: theme.palette.error.main }}
                />
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    console.log("addedmeters", addedSerialmeters);
  }, [addedSerialmeters]);

  const addedIds = addedSerialmeters?.map(
    (item) => item?.attributes?.identifier
  );
  useEffect(() => {
    props.onChange(addedIds);
  }, [addedIds]);

  const filteredSerialMeters = metersIndex?.filter(
    (item) => !addedIds?.includes(item?.attributes?.identifier)
  );

  const serialRows = filteredSerialMeters?.map((item) => ({
    id: item.id ? item.id : "--",

    class: item?.attributes?.class
      ? nameTransform(
          item?.attributes?.class === "lorawan_device"
            ? "LoRaWAN"
            : item?.attributes?.class
        )
      : "--",
    network_address:
      item?.attributes?.fields?.[item?.attributes?.fields?.identifier_type],
    serial: item?.attributes?.fields?.serial
      ? item?.attributes?.fields?.serial
      : "--",
    /*   metrological_serial: item?.attributes?.fields?.serial ?? "--", */
    condominium_name: item?.attributes?.fields?.condominium_name ?? "--",
    apartment_name: item?.attributes?.fields?.apartment_name ?? "--",
    last_reeading_date: item?.attributes?.fields?.last_reading?.datetime_utc
      ? t("dt", {
          val: new Date(item?.attributes?.fields?.last_reading?.datetime_utc),
        })
      : "--",
    address: item?.attributes?.fields?.address
      ? item?.attributes?.fields?.address
      : "--",

    configdate: item?.attributes?.inserted_at
      ? t("date_val", { val: new Date(item?.attributes?.inserted_at) })
      : "--",
    actions: {
      item: item,
      variant: "add",
    },
  }));
  const addedSerialRows = addedSerialmeters?.map((item) => ({
    id: item.id ? item.id : "--",

    class: item?.attributes?.class
      ? nameTransform(
          item?.attributes?.class === "lorawan_device"
            ? "LoRaWAN"
            : item?.attributes?.class
        )
      : "--",
    network_address:
      item?.attributes?.fields?.[item?.attributes?.fields?.identifier_type],
    serial: item?.attributes?.fields?.serial
      ? item?.attributes?.fields?.serial
      : "--",
    /*   metrological_serial: item?.attributes?.fields?.serial ?? "--", */
    condominium_name: item?.attributes?.fields?.condominium_name ?? "--",
    apartment_name: item?.attributes?.fields?.apartment_name ?? "--",
    last_reeading_date: item?.attributes?.fields?.last_reading?.datetime_utc
      ? t("dt", {
          val: new Date(item?.attributes?.fields?.last_reading?.datetime_utc),
        })
      : "--",
    address: item?.attributes?.fields?.address
      ? item?.attributes?.fields?.address
      : "--",

    configdate: item?.attributes?.inserted_at
      ? t("date_val", { val: new Date(item?.attributes?.inserted_at) })
      : "--",
    actions: {
      item: item,
      variant: "delete",
    },
  }));
  const onInMetersClick = () => {
    setInMeters((prev) => [...prev, ...selectedItems]);
    setSelectedItems([]);
    setCheckedItems({});
  };
  const onOutMetersClick = () => {
    setOutMeters((prev) => [...prev, ...selectedItems]);
    setSelectedItems([]);
    setCheckedItems({});
  };
  const inButtonDisabled = () => {
    return false;
  };
  const onSelectAllClick = () => {};

  const LastColumnButton = (
    <Box sx={{ width: "100%", ml: 2 }}>
      {!selectAll && (
        <IconButton disabled={rows?.length < 1}>
          <Tooltip title={t("select_all")} onClick={handleSelectAll}>
            <SelectAllIcon />
          </Tooltip>
        </IconButton>
      )}
      {selectAll && (
        <IconButton disabled={rows?.length < 1}>
          <Tooltip title={t("deselect_all")} onClick={handleUnselectAll}>
            <DeselectIcon />
          </Tooltip>
        </IconButton>
      )}
    </Box>
  );
  const columnsWithButton = [
    ...columns,
    { renderHeader: () => LastColumnButton, sortable: false },
  ];

  useEffect(() => {
    if (rows.length < 1) {
      setSelectAll(false);
    }
  }, [rows]);
  const handleChange = (e) => {
    setWaterType(e.target.value);
    dispatch(
      setBalancType(e.target.value == "all" ? ["AFS", "ACS"] : [e.target.value])
    );
  };

  const onSearchClick = () => {
    setFirstRender(false);
    getMeters({
      mechanical_serial_number: metroSerial,
      page: page,
      per_page: 12,
    });
  };
  useEffect(() => {
    if (searchBy == "serial" && !firstRender) {
      onSearchClick();
    }
  }, [page, searchBy, firstRender]);

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ fill: theme.palette.navIcon }} />}
        >
          {t("meters")}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid size={12} sx={{ mb: 2 }}>
              <FormControl component="fieldset">
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={searchBy === "serial"}
                        onChange={() => setSearchBy("serial")}
                        name="searchBySerial"
                        color="primary"
                      />
                    }
                    label={t("search_by_serial")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={searchBy === "block"}
                        onChange={() => setSearchBy("block")}
                        name="searchByBlock"
                        color="primary"
                      />
                    }
                    label={t("search_by_block")}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            {searchBy == "serial" && (
              <>
                {/*   <Grid size={6}>
                  <TextField fullWidth label={t("serial")} />
                </Grid> */}
                <Grid size={12}>
                  <TextField
                    onChange={(e) => setMetroSerial(e.target.value)}
                    fullWidth
                    label={t("metrological_serial")}
                  />
                </Grid>
                <Box
                  display={"flex"}
                  width={"100%"}
                  justifyContent={"flex-end"}
                >
                  <Button onClick={onSearchClick} disabled={!metroSerial}>
                    {t("search")}
                  </Button>
                </Box>

                <Grid size={6}>
                  <Typography sx={{ mb: 1 }} align="center">
                    {t("all_meters")}
                  </Typography>
                  <IwdDataGrid
                    columns={serialColumns}
                    perPage={12}
                    setPage={setPage}
                    rows={serialRows ?? []}
                    page={page}
                    count={metersIndexCount - addedSerialRows?.length}
                    paginationMode={"server"}
                    sort={false}
                    height={545}
                    tableName={"policy_serial_meters"}
                    customText={t("all_meters_was_added")}
                  />
                  <Box
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    <Tooltip title={t("add_all_meters")}>
                      <IconButton
                        disabled={filteredSerialMeters?.length < 1}
                        onClick={() =>
                          filteredSerialMeters?.length > 0 &&
                          addSerialMetersBatch(filteredSerialMeters)
                        }
                      >
                        <EastIcon
                          sx={{
                            fill: filteredSerialMeters?.length
                              ? theme.palette.navIcon
                              : "#808080",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid size={6}>
                  <Typography sx={{ mb: 1 }} align="center">
                    {t("added_meters")}
                  </Typography>
                  <IwdDataGrid
                    columns={serialColumns}
                    perPage={12}
                    setPage={setPage}
                    rows={addedSerialRows ?? []}
                    page={page}
                    count={addedSerialRows?.length}
                    paginationMode={"server"}
                    sort={false}
                    height={545}
                    tableName={"policy_serial_meters"}
                  />
                  <Tooltip title={t("remove_all_meters")}>
                    <IconButton
                      disabled={addedSerialmeters?.length == 0}
                      onClick={() => setSerialMeters([])}
                    >
                      <WestIcon
                        sx={{
                          fill: addedSerialmeters?.length
                            ? theme.palette.navIcon
                            : "#808080",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </>
            )}

            {searchBy == "block" && (
              <>
                <Grid size={12}>
                  <Autocomplete
                    disablePortal
                    options={
                      Array.from(
                        new Set(
                          originalMeters?.map(
                            (item) => item?.attributes?.sub_condominium_name
                          )
                        )
                      ) ?? []
                    }
                    onChange={(e, newValue) => {
                      setSubBlock(newValue?.label ?? newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label={t("condominium")} />
                    )}
                  />
                </Grid>
                <Grid size={!waterBalance ? 4 : 3}>
                  <Autocomplete
                    disablePortal
                    options={
                      Array.from(
                        new Set(
                          originalMeters?.map(
                            (item) => item?.attributes?.sub_condominium_name
                          )
                        )
                      ) ?? []
                    }
                    onChange={(e, newValue) => {
                      setSubBlock(newValue?.label ?? newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label={t("subcondominum")} />
                    )}
                  />
                </Grid>
                <Grid size={!waterBalance ? 4 : 3}>
                  <Autocomplete
                    disablePortal
                    options={
                      Array.from(
                        new Set(
                          originalMeters?.map(
                            (item) => item?.attributes?.apartment_name
                          )
                        )
                      ) ?? []
                    }
                    onChange={(e, newValue) => {
                      setApartment(newValue?.label ?? newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label={t("apartament")} />
                    )}
                  />
                </Grid>
                <Grid size={!waterBalance ? 4 : 3}>
                  <Autocomplete
                    disablePortal
                    options={
                      Array.from(
                        new Set(
                          originalMeters?.map(
                            (item) => item?.attributes?.serial_number
                          )
                        )
                      ) ?? []
                    }
                    onChange={(e, newValue) => {
                      setSerial(newValue?.label ?? newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label={t("serial_number")} />
                    )}
                  />
                </Grid>
                {waterBalance && (
                  <Grid item xs={3}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{`${t(
                          "water_type"
                        )}`}</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={waterType}
                          label={t("water_type")}
                          onChange={handleChange}
                          defaultValue=""
                        >
                          <MenuItem value={"all"}>All</MenuItem>
                          <MenuItem value={"ACS"}>ACS</MenuItem>
                          <MenuItem value={"AFS"}>AFS</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                )}
                <Box sx={{ height: 400, width: "100%", mt: 3 }}>
                  <StyledDataGrid
                    disableColumnMenu
                    rows={rows}
                    columns={columnsWithButton}
                    disableSelectionOnClick
                    getRowHeight={() => "auto"}
                  />
                </Box>
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <Button
                    disabled={inButtonDisable()}
                    onClick={onInMetersClick}
                    sx={{ mr: 1, mt: 2 }}
                    variant={window.BUTTON_TYPE}
                  >
                    Imposta entranti
                  </Button>
                  <Button
                    disabled={outButtonDisable()}
                    onClick={onOutMetersClick}
                    sx={{ mt: 2 }}
                    variant={window.BUTTON_TYPE}
                  >
                    Imposta uscenti
                  </Button>
                </Box>
              </>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default PolicyTranser;
